<template>
  <div class="flex flex-col items-center justify-center h-full w-full relative">
    <div class="absolute flex gap-2 top-0 right-0 p-2">
      <CameraProFlashLightToggle :enabled="flashLightEnabled" @click="toggleFlashLight" />
      <CameraProClose @click="$emit('close')" />
    </div>
    <div class="w-5/6 h-48 border border-rounded border-green-400" />
  </div>
</template>

<script setup lang="ts">
import { disableFlashLightPro, enableFlashLightPro } from '@/fsd/data/services/pro.service';
import { onUnmounted, ref, watch } from 'vue';
import CameraProClose from './CameraProClose.vue';
import CameraProFlashLightToggle from './CameraProFlashLightToggle.vue';

defineEmits(['close']);

const flashLightEnabled = ref(false);

const toggleFlashLight = () => {
  flashLightEnabled.value = !flashLightEnabled.value;
};

watch(flashLightEnabled, newValue => {
  if (newValue) {
    enableFlashLightPro();
  } else {
    disableFlashLightPro();
  }
});

onUnmounted(disableFlashLightPro);
</script>
