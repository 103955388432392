import IconRobotRounded from '@/fsd/shared/icons/robot/icon-robot-rounded.vue';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { OrderStageEnum } from '@/models/orders/BaseOrder';
import OrderOrder, { isOrderOrder } from '@/models/orders/OrderOrder';
import RobotProvision, { isRobotProvisionOrder } from '@/models/orders/RobotProvision';
import { useOrders } from '@/store/modules/orders';
import { confirmNotifyId, modalNotifyId } from '@/temp/constants/common';
import { $gettext } from '@/temp/plugins/gettext';
import { sleep } from '@/temp/utils';
import { notify } from '@kyvg/vue3-notification';
import { storeToRefs } from 'pinia';
import { computed, markRaw, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

/**
 * Словарь id заказов, для которых уже не надо показывать модалку о готовности робозоны
 */
export const shownModalsByOrder = ref<Record<OrderOrder['order_id'], boolean>>({});

export const useCheckReadyRobozone = () => {
  const ordersStore = useOrders();
  const router = useRouter();
  const route = useRoute();

  const { orderOrders, requestedOrders } = storeToRefs(ordersStore);

  /**
   * Список пользовательских заказов
   */
  const orders = computed(() => {
    return orderOrders.value.filter<OrderOrder>(isOrderOrder);
  });

  /**
   * Список заказов на снабжение робозоны
   */
  const robotProvisionOrders = computed(() => {
    return requestedOrders.value.filter<RobotProvision>(isRobotProvisionOrder);
  });

  /**
   * Незаблокированный заказ из робозоны, для которого нужно показать модальное окно
   */
  const unblockedRobozoneOrder = computed(() => {
    return orders.value.find(
      order => order.hasRobozoneSuggests && !order.hasBlockedSuggests && !shownModalsByOrder.value[order.order_id],
    );
  });

  /**
   * Документ снабжения робозоны, перешедший на стадию раскладки,
   * для которого роботы подвезли стеллаж
   */
  const unblockedRobozoneProvisionOrder = computed(() => {
    return robotProvisionOrders.value.find(
      order => order.vars.stage === OrderStageEnum.put && !shownModalsByOrder.value[order.order_id],
    );
  });

  /**
   * True, если пользователь на странице заказа
   */
  const isOrderPage = computed(() => {
    return route.name === 'order';
  });

  /**
   * True, если пользователь на домашней странице
   */
  const isHomePage = computed(() => {
    return route.name === 'home';
  });

  /**
   * Открывает страницу заказа
   * @param order
   */
  const openOrder = (order: OrderOrder | RobotProvision) => {
    notify.close(modalNotifyId);
    notify.close(confirmNotifyId);

    const pageName = isOrderOrder(order) ? 'order' : 'robot_provision';
    router.push({ name: pageName, params: { order_id: order.order_id } });
  };

  /**
   * Показывает модальное окно о возможности продолжить выполнение заказа из робозоны
   * @param order
   */
  const showRobozoneOrderUnblocked = async (order: OrderOrder | RobotProvision) => {
    const suggest = order.suggests.find(suggest => suggest.isRobozone);
    if (!suggest) return;

    const confirmed = await Modal.show({
      btnPosition: ButtonPositionsEnum.horizontal,
      title: isOrderOrder(order)
        ? $gettext('Роботы привезли товары из заказа %{doc_number}', { doc_number: order.orderNumberForView })
        : $gettext('Роботы привезли стеллажи для снабжения робозоны %{doc_number}', {
            doc_number: order.orderNumberForView,
          }),
      text: isOrderOrder(order)
        ? $gettext('Подойдите в роботизированную зону и продолжите сборку заказа')
        : $gettext('Подойдите в роботизированную зону и продолжите снабжение робозоны'),
      confirmBtnTitle: $gettext('Хорошо, иду'),
      closeBtnTitle: $gettext('Позже'),
      component: markRaw(IconRobotRounded),
    });

    shownModalsByOrder.value[order.order_id] = true;

    if (confirmed) {
      openOrder(order);
    }
  };

  /**
   * Проверяет наличие клиентского заказа с незаблокированнымы саджестами из робозоны
   */
  const checkClientOrderRobozoneReady = async () => {
    if (!unblockedRobozoneOrder.value) return;
    // Когда мы берем заказ в работу, то сначала он добавляется в стор, а только потом мы переходим на страницу ордера, пока придумал только такую заглушку
    await sleep(100);
    if (isOrderPage.value) return;
    showRobozoneOrderUnblocked(unblockedRobozoneOrder.value);
  };

  /**
   * Проверяет наличие ордера на пополнение робозоны, перешедего на стадию раскладки,
   * для которого приехал стеллаж
   */
  const checkRobozoneProvisionReady = async () => {
    if (!unblockedRobozoneProvisionOrder.value || !isHomePage.value) return;
    showRobozoneOrderUnblocked(unblockedRobozoneProvisionOrder.value);
  };

  watch(unblockedRobozoneOrder, value => {
    if (!value) return;
    checkClientOrderRobozoneReady();
  });

  watch(unblockedRobozoneProvisionOrder, value => {
    if (!value) return;
    checkRobozoneProvisionReady();
  });

  watch(isHomePage, value => {
    if (!value) return;
    checkClientOrderRobozoneReady();
    checkRobozoneProvisionReady();
  });
};
