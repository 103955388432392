<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.50016 5.83337C2.03993 5.83337 1.66683 6.20647 1.66683 6.66671V7.50004H18.3335V6.66671C18.3335 6.20647 17.9604 5.83337 17.5002 5.83337H2.50016ZM0.833496 10C0.833496 9.07957 1.57969 8.33337 2.50016 8.33337H10.0002H11.6668H16.6668C18.0475 8.33337 19.1668 9.45266 19.1668 10.8334V13.9941C19.1668 14.1046 19.1229 14.2106 19.0448 14.2887L18.5776 14.756C18.4213 14.9122 18.2093 15 17.9883 15H12.0603C11.8015 15 11.5463 14.9398 11.3149 14.8241L10.3521 14.3427C10.1206 14.2269 9.86546 14.1667 9.60672 14.1667H8.72089C9.00451 13.6764 9.16683 13.1072 9.16683 12.5C9.16683 10.6591 7.67444 9.16671 5.8335 9.16671C3.99255 9.16671 2.50016 10.6591 2.50016 12.5C2.50016 13.1072 2.66249 13.6764 2.9461 14.1667H2.50016C1.57969 14.1667 0.833496 13.4205 0.833496 12.5V10ZM14.1668 11.6667C14.1668 12.1269 13.7937 12.5 13.3335 12.5C12.8733 12.5 12.5002 12.1269 12.5002 11.6667C12.5002 11.2065 12.8733 10.8334 13.3335 10.8334C13.7937 10.8334 14.1668 11.2065 14.1668 11.6667ZM17.9168 11.6667C17.9168 12.1269 17.5437 12.5 17.0835 12.5C16.6233 12.5 16.2502 12.1269 16.2502 11.6667C16.2502 11.2065 16.6233 10.8334 17.0835 10.8334C17.5437 10.8334 17.9168 11.2065 17.9168 11.6667ZM8.3335 12.5C8.3335 13.8808 7.21421 15 5.8335 15C4.45278 15 3.3335 13.8808 3.3335 12.5C3.3335 11.1193 4.45278 10 5.8335 10C7.21421 10 8.3335 11.1193 8.3335 12.5Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script setup lang="ts">
import { useColor } from '@/fsd/shared/hooks';

interface Props {
  bgColor?: string;
  color?: string;
}

const { bgColor = '#FFA60D', color = 'white' } = defineProps<Props>();
const params = {
  color: () => color,
  bgColor: () => bgColor,
};
const { colorValue } = useColor(params);
</script>
