import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';

export const mergeTrueMarkSuggests = (suggests: Suggest[]): (Suggest | TrueMarkSuggestWrapper)[] => {
  const mergedSuggests: (Suggest | TrueMarkSuggestWrapper)[] = [];

  suggests.forEach(suggest => {
    if (suggest.conditions.need_true_mark) {
      const virtualId = suggest.product_id + suggest.shelf_id + suggest.type;
      const virtualSuggest = mergedSuggests.find(s => s.suggest_id === virtualId);
      if (virtualSuggest && TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(virtualSuggest)) {
        virtualSuggest.addSuggest = suggest;
      } else {
        mergedSuggests.push(new TrueMarkSuggestWrapper({ ...suggest, suggests: [suggest], suggest_id: virtualId }));
      }
    } else {
      mergedSuggests.push(suggest);
    }
  });

  return mergedSuggests;
};
