import { api } from '@/fsd/data/api/api.service';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import Product, { TypeAccountingEnum } from '@/models/Product';
import ProductByBarcode from '@/models/ProductByBarcode';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import BaseOrder, { AcceptanceModeAttrEnum, OrderTypeEnum } from '@/models/orders/BaseOrder';
import { isString } from '@/models/typeGuards';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import { computed } from 'vue';

interface FindedProduct {
  product?: Product;
  findSuggest?: Suggest;
  childProduct?: Product;
}

interface FindedParent {
  suggest?: Suggest;
  parent?: Product;
  child?: Product;
}

export const useHandleProductCode = (order_id: BaseOrder['order_id']) => {
  const ordersStore = useOrders();
  const productStore = useProducts();

  const order = computed(() => ordersStore.orderById(order_id));

  const suggests = computed<Suggest[]>(() => order.value?.suggests || []);

  const isSimpleBarcode = (barcode: string) =>
    (barcode.length !== 13 && barcode.length !== 18) || (barcode[0] !== '2' && barcode[0] !== '6');

  const productBySuggest = (products: Product[], suggestWithProduct: Suggest) => {
    return products.find(p => p.product_id === suggestWithProduct.product_id)!;
  };

  const checkParents = async (products: Product[]): Promise<FindedParent> => {
    const result: FindedParent = {};

    const parentIds = products.map(p => p.parent_id).filter(isString);
    if (!parentIds.length) return result;

    const parents = await productStore.getProductsByIds(parentIds);
    if (!parents || !parents.length) return result;

    const suggestsWithProduct = suggests.value.filter(s => {
      return parents.find(p => p.product_id === s.product_id);
    });
    if (!suggestsWithProduct.length) return result;

    if (suggestsWithProduct.length === 1) {
      result.suggest = suggestsWithProduct[0];
      const product = productBySuggest(parents, suggestsWithProduct[0]);
      result.parent = product;
      result.child = products.find(r => r.parent_id === product.product_id);
      return result;
    }
    if (suggestsWithProduct.length > 1) {
      const requestSuggest = suggestsWithProduct.find(s => s.status === SuggestStatusEnum.request);
      if (requestSuggest) {
        result.suggest = requestSuggest;
        const product = productBySuggest(parents, requestSuggest);
        result.parent = product;
        result.child = products.find(p => p.parent_id === product.product_id);
      } else {
        result.suggest = suggestsWithProduct[0];
        const product = productBySuggest(products, suggestsWithProduct[0]);
        result.parent = product;
        result.child = products.find(p => p.parent_id === product.parent_id);
      }
      return result;
    }
    return result;
  };

  const checkBarcode = async (barcode: string): Promise<Product[]> => {
    try {
      if (isSimpleBarcode(barcode)) {
        //  Простой случай, можно попробовать найти по баркоду в сторе
        const products = productStore.productsByBarcode(barcode);
        if (products.length) return products;
      }
      //  если ничего не нашли локально, то делаем запрос по ручке баркод
      const { data } = await api.barcode({ barcode });
      const founds = data.found.filter(p => p.type === 'product');
      if (!founds.length) {
        Alerts.error($gettext('Не найден штрихкод %{barcode}', { barcode }));
        throw new Error('check barcode');
      }

      const product_ids = founds.filter(ProductByBarcode.isProductByBarcode).map(p => p.product_id);
      const results: Product[] = [];
      if (product_ids.length) {
        const products = (await productStore.getProductsByIds(product_ids)) || [];

        for (const product of products) {
          if (product.type_accounting !== TypeAccountingEnum.weight || product.parent_id) {
            results.push(product);
          } else {
            // нужно проверить детей. если они НЕ весовые то разрешаем идти дальше
            if (product.children_id?.length) {
              //Если есть дети
              const child = await productStore.getProductById(product.children_id[0]);
              // И они не весовые
              if (child?.type_accounting === TypeAccountingEnum.unit) {
                results.push(product);
              }
            }
          }
        }
      }
      return results;
    } catch (error) {
      console.error(error);
      Alerts.error($gettext('Не найден штрихкод'));
      throw error;
    }
  };

  const getSuggestsWithProducts = (products: Product[]): Suggest[] => {
    return suggests.value.filter(s => {
      return products.find(p => p.product_id === s.product_id);
    });
  };

  const findNeededProduct = async (products: Product[], barcode: string): Promise<FindedProduct> => {
    const result: FindedProduct = {};
    const suggestsWithProduct = getSuggestsWithProducts(products);
    if (!suggestsWithProduct.length) {
      if (!isSimpleBarcode(barcode)) {
        const { parent, child, suggest } = await checkParents(products);
        if (suggest) {
          result.product = parent;
          result.childProduct = child;
          result.findSuggest = suggest;
          return result;
        }
      }
      if (
        order.value?.type === OrderTypeEnum.acceptance &&
        order.value?.attr.acceptance_mode === AcceptanceModeAttrEnum.pre_check
      ) {
        result.product = products.find(Product.isProduct);
        return result;
      }
      Alerts.error($gettext('Продукта нет в задании'));
      throw new Error('this product is not in order');
    }
    if (suggestsWithProduct.length === 1) {
      result.findSuggest = suggestsWithProduct[0];
      result.product = productBySuggest(products, suggestsWithProduct[0]);
      return result;
    }
    if (suggestsWithProduct.length > 1) {
      const requestSuggest = suggestsWithProduct.find(s => s.status === SuggestStatusEnum.request);
      if (requestSuggest) {
        result.findSuggest = requestSuggest;
        result.product = productBySuggest(products, requestSuggest);
      } else {
        result.findSuggest = suggestsWithProduct[0];
        result.product = productBySuggest(products, suggestsWithProduct[0]);
      }
    }
    return result;
  };

  return { checkBarcode, findNeededProduct };
};
