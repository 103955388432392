export const GS = String.fromCharCode(29);

interface TrueMarkUnpackResult {
  true_mark: string;
  gtin: string;
  // Вес - группа чисел после признака группы. Дока весьма запутана https://ref.gs1.org/ai/
  // будто бы вес может лежать и под другими группами, перед использованием нужно подробнее поресерчить
  weight?: number;
  serial?: string;
  id_check_key?: string;
  check_big?: string;
  check_small?: string;
}

export const isTrueMark = (mark: string): boolean => {
  // марка должны быть длиннее, чем признак gtin(2)+ сам gtin(14) и сепаратор(2)
  if (mark.length < 2 + 14 + 2) {
    return false;
  }
    // в начале марки всегда должен быть признак gtin("01")
  if (mark.slice(0, 2) !== '01') {
    return false;
  }
  // после gtin обязательно должен быть признак серии("21")
  if (mark.slice(2 + 14, 2 + 14 + 2) !== '21') {
    return false;
  }
  if (!mark.includes(GS)) {
    return false;
  }
  return true;
};

export const unpackTrueMark = (mark: string): TrueMarkUnpackResult | null => {
  // легпром: 129
  // духи и туалетная вода: 85
  // молочная продукция: 31
  // молочная продукция с весом: 42
  // упакованная вода: 38
  if (!isTrueMark(mark)) {
    console.error(mark, 'not true mark');
    return null;
  }

  // Словарик из составных частей марки. [признак начала части, название части и ее длина(если ноль - то не фиксированная)]
  // Порядок словаря важен, именно в этом порядке мы извлекаем составные части из марки. При добавлении нужно обращать внимание на признак начала части. 
  // "21" должен быть указан раньше, чем "50"
  const trueMarkParts: [string, string, number][] = [
    ['01', 'gtin', 14],
    ['21', 'serial', 0],
    ['91', 'id_check_key', 0],
    ['92', 'check_big', 0],
    ['93', 'check_small', 0],
    ['3103', 'weight', 6],
  ];
  const result: TrueMarkUnpackResult = {
    true_mark: mark,
    gtin: '',
  };

  // пытаемся найти все части в марке. Нам нужен только gtin, но берем все на всякий случай.
  trueMarkParts.forEach(([sign, name, length]) => {
    if (!mark.startsWith(sign)) {
      return;
    }
    mark = mark.slice(sign.length);
    // Если длина фиксированная - то забираем только необходимое кол-во символов, иначе берем до сепаратора.
    if (length) {
      result[name] = mark.slice(0, length);
      mark = mark.slice(length);
      return;
    }
    const separator_idx = mark.indexOf(GS);
    if (separator_idx === -1) {
      result[name] = mark;
      mark = '';
    } else {
      result[name] = mark.slice(0, separator_idx);
      mark = mark.slice(separator_idx + 1);
    }
  });

  if (result.gtin?.startsWith('0')) {
    result.gtin = result.gtin.slice(1);
  }
  if (result.weight !== undefined) {
    // кастуем тип чтобы преобразовать вес в число
    result.weight = parseInt(result.weight as unknown as string);
  }
  return result;
};
