export const openCameraPro = () => {
  // @ts-expect-error
  window.flutter_inappwebview?.callHandler('openCamera');
};

export const closeCameraPro = () => {
  // @ts-expect-error
  window.flutter_inappwebview?.callHandler('closeCamera');
};

export const logoutPro = () => {
  // @ts-expect-error
  window.flutter_inappwebview?.callHandler('pop');
};

export const enableFlashLightPro = () => {
  // @ts-expect-error
  window.flutter_inappwebview?.callHandler('addFlashlight');
};

export const disableFlashLightPro = () => {
  // @ts-expect-error
  window.flutter_inappwebview?.callHandler('deleteFlashlight');
};

export const vibrationPro = (n: number) => {
  // @ts-expect-error
  window.flutter_inappwebview?.callHandler('vibration', n);
};
