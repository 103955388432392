<template>
  <div>
    <slot />
    <div class="flex items-center px-4 pb-3 bg-main">
      <div class="flex grow items-center h-[52px] pl-4 bg-card rounded-2xl">
        <div
          class="flex grow text-base text-primary h-6 items-center"
          :class="{
            'error-animation': hasError,
            invalid: !valid,
            'border-r border-solid border-cloudGray-500': type !== 'pin',
          }"
        >
          <Body2 data-test="keyboard field">
            {{ visibleCount }}
          </Body2>
          <span v-if="needShowAdditionalValue" class="text-base text-secondary" data-test="field-placeholder">
            {{ additionalValue }}
          </span>
          <div v-if="!value" class="text-base text-secondary" data-test="field-placeholder">
            {{ placeholder || $gettext('Введите кол-во') }}
          </div>
        </div>
        <UiButton
          v-if="type !== 'pin'"
          class="!bg-card !border-none"
          is-icon
          data-test="toggle keyboard btn"
          @click="toggleKeyboard"
        >
          <img v-if="!isVisible" src="./img/show.svg" alt="" />
          <img v-if="isVisible" src="./img/hide.svg" alt="" />
        </UiButton>
      </div>
      <UiButton
        v-if="!value && type === 'temperature'"
        background-color="secondary"
        class="ml-2 !w-auto"
        @click="onNoThermometerClick"
      >
        {{ $gettext('Нет термометра') }}
      </UiButton>
      <UiButton
        v-else
        is-icon
        class="ml-2"
        :is-disabled="!!$attrs.disabled || !valid"
        data-test="keyboard send btn"
        @click="onInputClick"
      >
        <img class="icon-check" src="@/assets/img/check.svg" alt="" />
      </UiButton>
    </div>
    <BaseKeyboard :is-visible="isVisible" :type="type" @key-click="onKeyClick" />
  </div>
</template>
<script setup lang="ts">
import { defaultSourceFormat, getDefaultLocalFormat } from '@/temp/constants/dateFormat';
import { $gettext } from '@/temp/plugins/gettext';
import BaseKeyboard from '@/ui/common/keyboard/base-keyboard.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { getFormatDate } from '@/utils';
import dayjs from 'dayjs';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

// В режиме даты принимает и отдает дату в формате YYYY-MM-DD
// поля для валидации в формате YYYY-MM-DD

interface KeyboardProps {
  placeholder?: string;
  type?: 'pin' | 'number' | 'temperature' | 'date';
  min?: string | number;
  max?: string | number;
  isOpen?: boolean;
  initialValue?: string | number;
  productId?: string;
}
const props = withDefaults(defineProps<KeyboardProps>(), {
  type: 'number',
  isOpen: true,
  initialValue: '',
});

const value = ref<string>('');
const hasError = ref<boolean>(false);
const isVisible = ref<boolean>(props.isOpen);
let timerId: number | undefined = undefined;

const emits = defineEmits<{
  (e: 'changeVisible', value: boolean): void;
  (e: 'input', value: string | number | null): void;
}>();

const needShowAdditionalValue = computed(() => {
  return props.type === 'date' && value.value;
});

const visibleCount = computed(() => {
  if (props.type === 'temperature' && value.value) {
    return value.value + '°C';
  }
  return value.value;
});

const additionalValue = computed(() => {
  if (props.type === 'date' && props.placeholder) {
    return props.placeholder.slice(visibleCount.value.length);
  }
  return '';
});

const parsedDate = computed(() => {
  const inputDate = dayjs(value.value, getDefaultLocalFormat());
  return inputDate.format(defaultSourceFormat);
});

const parsedValue = computed(() => {
  switch (props.type) {
    case 'pin':
      return value.value;
    case 'date':
      return parsedDate.value;
    case 'number':
      return Number(value.value);
    default:
      return Number(value.value);
  }
});

const numberValidator = (value: string): boolean => {
  const number = Number(value);
  return !isNaN(number) && (!props.max || (number <= Number(props.max) && (!props.min || number >= Number(props.min))));
};

const numberInputValidator = (value): boolean => {
  const number = Number(value);
  return !isNaN(number);
};

const pinValidator = (value: string): boolean => {
  return value.length === 4 && Boolean(value.match(/^\d+$/));
};

const pinInputValidator = (value: string): boolean => {
  return value.length <= 4 && Boolean(value.match(/^\d+$/));
};

const temperatureInputValidator = (value: string): boolean => {
  if (value === '-') return true;
  const number = Number(value);
  return !isNaN(number);
};

const dateValidator = (value: string): boolean => {
  const inputDate = dayjs(value, getDefaultLocalFormat());
  if (props.min) {
    const minDate = dayjs(props.min, defaultSourceFormat);
    const isAfterOrSame = minDate.isBefore(inputDate) || minDate.isSame(inputDate);
    if (!isAfterOrSame) return false;
  }
  if (props.max) {
    const maxDate = dayjs(props.max, defaultSourceFormat);
    const isBeforeOrSame = maxDate.isAfter(inputDate) || maxDate.isSame(inputDate);

    if (!isBeforeOrSame) return false;
  }
  return Boolean(value.match(/^[0-3]?[0-9].[01]?[0-9].[0-9][0-9]$/));
};

const dateInputValidator = (value: string): boolean => {
  const [day, month, year] = value.split('.').filter(p => p);
  const dayValid = day === undefined || (Number(day) >= 0 && Number(day) <= 31);
  const monthValid = month === undefined || (Number(month) >= 0 && Number(month) <= 12);
  const yearValid = year === undefined || (Number(year) >= 0 && Number(year) <= 99);
  return dayValid && monthValid && yearValid;
};

const validator = (value: string): boolean => {
  switch (props.type) {
    case 'number':
    case 'temperature':
      return numberValidator(value);
    case 'pin':
      return pinValidator(value);
    case 'date':
      return dateValidator(value);
    default:
      return numberValidator(value);
  }
};

const inputValidator = (value: string): boolean => {
  switch (props.type) {
    case 'number':
      return numberInputValidator(value);
    case 'temperature':
      return temperatureInputValidator(value);
    case 'pin':
      return pinInputValidator(value);
    case 'date':
      return dateInputValidator(value);
    default:
      return numberInputValidator(value);
  }
};

const valid = computed(() => {
  return validator(value.value);
});

const normalizeDate = () => {
  const parts = value.value.split('.').filter(p => p);
  const lastSymbol = value.value[value.value.length - 1];
  switch (true) {
    //Если последний введенный символ точка, то добавляем (если нужно) ведущий ноль для числа или месяца
    case lastSymbol === '.':
      parts[parts.length - 1] = parts[parts.length - 1].padStart(2, '0');
      value.value = parts.join('.') + '.';
      break;
    //Если последний ввел число или месяц и в нем 2 цифры, то добавляем точку
    case parts.length <= 2 && parts[parts.length - 1].length === 2:
      value.value = value.value + '.';
      break;
  }
};

const setError = () => {
  hasError.value = true;
  timerId = setTimeout(() => {
    hasError.value = false;
  }, 1000);
};

const onKeyClick = key => {
  if (key === 'D' && !value.value) {
    return;
  }
  if (key === 'D' && value.value) {
    value.value = value.value.slice(0, -1);
    return;
  }
  const newValue = value.value + key;
  const valid = inputValidator(newValue);
  if (valid) {
    value.value = newValue;
    if (props.type === 'date') {
      normalizeDate();
    }
    return;
  } else {
    setError();
  }
};

const toggleKeyboard = () => {
  isVisible.value = !isVisible.value;
  emits('changeVisible', isVisible.value);
};

const onInputClick = () => {
  emits('input', parsedValue.value);
};

const onNoThermometerClick = () => {
  emits('input', null);
};

onMounted(() => {
  value.value = props.initialValue ? getFormatDate(props.initialValue.toString()) : '';
});

onBeforeUnmount(() => {
  if (timerId) {
    clearTimeout(timerId);
  }
});
</script>

<style scoped lang="scss">
.error-animation {
  animation: error-animation 1s linear;
}

.invalid {
  color: #d4412e;
}

@keyframes error-animation {
  0% {
    color: var(--text-primary-color);
  }
  50% {
    color: #d4412e;
  }
  100% {
    color: var(--text-primary-color);
  }
}
</style>
