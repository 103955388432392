<template>
  <div class="bar">
    <button class="bar-button" data-test="app bar options" @click="$emit('show-profile')">
      <IconSettings class="primary-color-icon" />
    </button>
    <div class="content">
      <Body2 class="font-medium text-center" color="primary" data-test="app bar user name">
        {{ name }}
      </Body2>
      <Caption1 class="mt-0.5 text-center" color="secondary" data-test="app bar order count">
        {{
          $ngettext('%{count} задание', '%{count} заданий', requestedOrdersCount, {
            count: requestedOrdersCount.toString(),
          })
        }}
      </Caption1>
    </div>
    <button data-test="main menu exit btn" class="bar-button" @click="onExitClick">
      <IconExit />
    </button>
  </div>
</template>
<script lang="ts">
import { PlatformService } from '@/fsd/data/services/platform.service';
import { logoutPro } from '@/fsd/data/services/pro.service';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import IconExit from '@/temp/icons/icon-exit.vue';
import IconSettings from '@/temp/icons/icon-settings.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Caption1,
    Body2,
    IconExit,
    IconSettings,
  },
  emits: ['show-profile'],
  setup() {
    const userStore = useUser();
    const ordersStore = useOrders();

    return { userStore, ordersStore };
  },
  computed: {
    name(): string {
      return this.userStore.name;
    },
    requestedOrdersCount(): number {
      return this.ordersStore.requestedOrders.length;
    },
  },
  methods: {
    async onExitClick(): Promise<void> {
      const confirmed = await this.$modal.show({
        title: this.$gettext('Вы уверены, что хотите выйти?'),
        btnPosition: ButtonPositionsEnum.horizontal,
      });
      if (confirmed) {
        this.userStore.logout();
        if (PlatformService.isPro()) {
          logoutPro();
        }
      }
    },
  },
});
</script>
<style scoped lang="scss">
.bar {
  height: 60px;
  align-items: center;
  display: flex;
  width: 100%;
  background-color: var(--main-bg);
}

.bar-button {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem;
  height: 100%;
  min-width: 60px;
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
}
</style>
