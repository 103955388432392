<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :caption="captionText"
        :text="controlBarText"
        need-progress
        :progress-config="progressConfig"
        :total="suggests.length"
        @close-click="toHomePage"
      />
    </template>

    <template #default>
      <div class="flex flex-col h-full">
        <Hint v-if="filteredSuggests.length !== 0" class="my-2 mx-4">
          {{ hintText }}
        </Hint>
        <SuggestCardContainer
          v-if="order"
          doc-type="robot_provision"
          :order="order"
          :suggest-menu-config="getSuggestMenuConfig"
          :suggests="
            sortedFilteredSuggests.map(s => {
              s.need_menu = s.status === 'request';
              return s;
            })
          "
          @open-suggest-menu="needBarcodeRequest = false"
          @close-suggest-menu="needBarcodeRequest = true"
        />
      </div>

      <SuggestDetails
        v-if="order && suggestDetails.visible.value"
        :suggest-id="suggestDetails.props.value.suggest_id"
        :order-id="order.order_id"
        @cancel="suggestDetails.hide"
        @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
      />

      <RackValidation
        v-if="rackValidation.visible.value"
        :suggests="suggests"
        :order="order"
        @validated="rackValidation.hide"
        @close-click="toHomePage"
      />

      <CountWindow v-if="countWindow.visible.value" @finish="value => noProduct(value, countWindow.props.value)" />
    </template>

    <template #footer>
      <LayoutFooter>
        <FilterMenu v-if="filterState === 'all'" :menu-config="filterMenuConfig" />
        <UiButton
          v-if="stage === 'take'"
          data-test="hand_move footer next-stage-btn"
          :disabled="!allSuggestDone || waitingForRacks"
          @click="next_stage"
        >
          {{ nextStageButtonText }}
        </UiButton>
        <UiButton
          v-if="filterState === 'all' && stage === 'put'"
          data-test="hand_move footer finish-btn"
          :disabled="!allSuggestDone"
          @click="finishOrder"
        >
          {{ $gettext('Завершить') }}
        </UiButton>
        <UiButton v-if="filterState !== 'all'" @click="setFilter('all')">
          {{ $gettext('Назад') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import { useSubscribeOnOrder } from '@/fsd/data/utils/subscribeOnOrder';
import { getOrderTitle } from '@/fsd/entities/order/tools/getOrderTitle';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import {
  getBox2ShelfSuggests,
  getEmptyDoneSuggests,
  getFullDoneSuggests,
  getPartDoneSuggests,
  getRequestSuggests,
  getShelf2BoxSuggests,
} from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { shownModalsByOrder } from '@/fsd/features/order/remainders/useCheckReadyRobozone';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import IconRobotRounded from '@/fsd/shared/icons/robot/icon-robot-rounded.vue';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest, { SuggestTypeEnum } from '@/models/Suggest';
import { OrderEstatusEnum, OrderTargetEnum } from '@/models/orders/BaseOrder';
import RobotProvision from '@/models/orders/RobotProvision';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { modalNotifyId } from '@/temp/constants/common';
import { $gettext, $ngettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { sleep } from '@/temp/utils';
import Bar from '@/ui/common/bar/bar.vue';
import { getDefaultProgressConfig } from '@/ui/common/bar/getDefaultProgressConfig';
import CountWindow from '@/ui/common/count-window/count-window.vue';
import FilterMenu from '@/ui/common/filter-menu/filter-menu.vue';
import { FilterMenuItemConfig } from '@/ui/common/filter-menu/types';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import RackValidation from '@/ui/robots/RackValidation.vue';
import SuggestCardContainer from '@/views/common/suggest-card-container.vue';
import { notify } from '@kyvg/vue3-notification';
import { computed, markRaw, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { getSuggestFinishConfirmTitle } from './helpers';
import { SuggestsFilterState } from './types';

interface RobotProvisionProps {
  order_id: string;
}

const props = defineProps<RobotProvisionProps>();

const { showLoader } = useLoader();
const ordersStore = useOrders();
const productsStore = useProducts();
const suggestDetails = useComponent<Suggest>();
const countWindow = useComponent<Suggest>();
const rackValidation = useComponent<Suggest[], Shelf>();
const router = useRouter();
const { needBarcodeRequest } = useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader();
  const product = await productsStore.getProductByBarcode(barcode);
  closeLoader();

  if (!product) {
    Alerts.error($gettext('Не найден штрихкод'));
    return true;
  }

  await selectProduct(product);

  return true;
});

useHandleOrderStatus(props.order_id);

const filterState = ref<SuggestsFilterState>('all');

const order = computed((): RobotProvision | undefined => {
  return ordersStore.orderById(props.order_id);
});

/**
 * True, если заказ отменен
 */
const isOrderCancelled = computed((): boolean => {
  return order.value?.target === 'canceled' || order.value?.target === 'failed';
});

const captionText = computed(() => getOrderTitle(props.order_id).value);
const contractorName = computed(() => order.value?.attr.contractor || '');

const stage = computed((): string => {
  return order.value?.vars?.stage || 'take';
});

/**
 * Текст, подсказывающий что нужно сделать кладовщику на текущем этапе выполнения заказа
 */
const hintText = computed((): string => {
  if (isOrderCancelled.value) {
    return $gettext('Пожалуйста, разложите все собранные товары обратно');
  } else if (stage.value === 'take') {
    return $gettext('Отсканируйте и возьмите товар');
  } else {
    return $gettext('Отсканируйте и разместите товар в робозоне');
  }
});

/**
 * True, если ожидаем приезда роботов на станцию
 */
const waitingForRacks = computed((): boolean => {
  return order.value?.estatus === OrderEstatusEnum.waiting_racks;
});

/**
 * Текст кнопки перехода на этап раскладки
 */
const nextStageButtonText = computed((): string => {
  return waitingForRacks.value ? $gettext('Ожидание подвоза стеллажа') : $gettext('Перейти к размещению');
});

/**
 * True, если требуется валидация стеллажа
 */
const isRackValidationNeeded = computed((): boolean => {
  return stage.value === 'put' && !isOrderCancelled.value;
});

const suggests = computed((): Suggest[] => {
  if (!order.value) return [];

  if (isOrderCancelled.value) {
    return order.value.suggests.filter(
      suggest => suggest.vars.target === OrderTargetEnum.canceled || suggest.vars.target === OrderTargetEnum.failed,
    );
  }

  return stage.value === 'take'
    ? getShelf2BoxSuggests(order.value.suggests)
    : getBox2ShelfSuggests(order.value.suggests);
});
const requestSuggests = computed(() => getRequestSuggests(suggests.value));
const fullCompletedSuggests = computed(() => getFullDoneSuggests(suggests.value));
const partiallyCompletedSuggests = computed(() => getPartDoneSuggests(suggests.value));
const noProductSuggests = computed(() => getEmptyDoneSuggests(suggests.value));
const filteredSuggests = computed(() => {
  switch (filterState.value) {
    case 'all':
      return suggests.value;
    case 'done':
      return fullCompletedSuggests.value;
    case 'request':
      return requestSuggests.value;
    case 'part':
      return partiallyCompletedSuggests.value;
    case 'noProduct':
      return noProductSuggests.value;
  }
  return suggests.value;
});
const sortedFilteredSuggests = computed(() => {
  return [...filteredSuggests.value].sort((a, b) => {
    switch (true) {
      case a.status === b.status:
        return 0;
      case a.status === 'request':
        return -1;
      case b.status === 'request':
        return 1;
      default:
        return 0;
    }
  });
});
const allSuggestDone = computed(() => requestSuggests.value.length === 0);

const controlBarText = computed(() => {
  switch (filterState.value) {
    case 'all':
      return $gettext('Осталось %{request} из %{all} позиций', {
        request: String(requestSuggests.value.length),
        all: String(suggests.value.length),
      });
    case 'done':
    case 'request':
    case 'part':
    case 'noProduct':
      return $ngettext(
        '%{filtered} позиция с таким статусом',
        '%{filtered} позиций с таким статусом',
        filteredSuggests.value.length,
        { filtered: String(filteredSuggests.value.length) },
      );
  }
  return contractorName.value;
});

const progressConfig = computed(() => {
  if (!order.value) return;
  if (order.value.type === 'order') {
    if (isOrderCancelled.value) {
      return [
        {
          count: getBox2ShelfSuggests(fullCompletedSuggests.value).length,
          color: 'green',
        },
      ];
    }
  }
  return getDefaultProgressConfig(order.value.suggests);
});

const getSuggestMenuConfig = (suggest: Suggest): MenuItemConfig[] => {
  const menuConfig: MenuItemConfig[] = [];
  const noProductBtn: MenuItemConfig = {
    buttonText: $gettext('Недостаточное количество'),
    dataTest: 'suggest-menu no-product btn',
    onClick: () => {
      countWindow.asyncShow(suggest);
    },
    condition: () => suggest.type === SuggestTypeEnum.shelf2box,
  };
  menuConfig.push(noProductBtn);

  return menuConfig;
};

const setFilter = (newValue: SuggestsFilterState) => (filterState.value = newValue);

const filterMenuConfig = computed((): FilterMenuItemConfig[] => [
  {
    buttonText: $gettext('Не отсканированные'),
    color: 'gray',
    count: requestSuggests.value.length,
    onClick: () => setFilter('request'),
  },
  {
    buttonText: $gettext('Готово'),
    color: 'green',
    count: fullCompletedSuggests.value.length,
    onClick: () => setFilter('done'),
  },
]);

const showRackValidation = async () => {
  needBarcodeRequest.value = false;

  try {
    await rackValidation.asyncShow(suggests.value);
  } catch (e) {
    console.error(e);
  } finally {
    needBarcodeRequest.value = true;
  }
};

watch(
  isRackValidationNeeded,
  newVal => {
    if (newVal) {
      showRackValidation();
    } else if (rackValidation.visible.value) {
      rackValidation.hide();
    }
  },
  { immediate: true },
);

/**
 * Выбирает продукт из списка саджестов и открывает его карточку
 * @param product
 */
const selectProduct = async (product: Product) => {
  if (!order.value) return true;

  const productSuggests = order.value.suggests.filter(
    s =>
      s.product_id === product.product_id &&
      s.status === 'request' &&
      (s.type === (stage.value === 'take' ? SuggestTypeEnum.shelf2box : SuggestTypeEnum.box2shelf) ||
        isOrderCancelled.value),
  );

  if (productSuggests.length === 0) {
    Alerts.error($gettext('Этот товар уже перемещен'));
    return true;
  }

  await suggestDetails.asyncShow(productSuggests[0]);
  return true;
};

const toHomePage = () => router.push({ name: 'home' });

/**
 * Показывает модальное окно о том, что стеллажи еще не приехали на станцию
 */
const showRackOnTheWayModal = async () => {
  await Modal.show({
    title: $gettext('Роботы еще везут стеллаж для снабжения %{orderNum}', {
      orderNum: order.value?.orderNumberForView ?? '',
    }),
    text: $gettext('Сообщим, когда стеллаж будет на месте'),
    btnPosition: ButtonPositionsEnum.horizontal,
    confirmBtnTitle: $gettext('На главную'),
    closeBtnTitle: $gettext('Назад к снабжению'),
    onConfirm: () => {
      toHomePage();
    },
    component: markRaw(IconRobotRounded),
  });
};

/**
 * Ожидает успешного ответа от сервиса роботов при взятии задания в работу
 */
const waitForRobotsResponse = async () => {
  if (!order.value || order.value.estatus !== OrderEstatusEnum.call_racks_replenishment) return;

  const { closeLoader, updateLoader } = showLoader(undefined, props.order_id);
  updateLoader($gettext('Ожидаем ответа от сервиса роботов'), props.order_id);

  const waitForRobotsResponse = useSubscribeOnOrder(props.order_id)(
    order => !order || order.estatus !== OrderEstatusEnum.call_racks_replenishment,
  );

  await Promise.race([sleep(10000), waitForRobotsResponse]);

  if (order.value?.estatus === OrderEstatusEnum.call_racks_replenishment) {
    Modal.show({
      title: $gettext('Произошла ошибка при вызове роботов'),
      text: $gettext('Обратитесь к оператору робозоны, для проверки состояния роботов'),
      confirmBtnTitle: $gettext('На главную'),
      onConfirm: () => {
        toHomePage();
      },
    });
  }

  closeLoader();
};

onMounted(async () => {
  if (waitingForRacks.value) {
    showRackOnTheWayModal();
  }

  if (order.value?.estatus === OrderEstatusEnum.call_racks_replenishment) {
    waitForRobotsResponse();
  }
});

watch(waitingForRacks, newVal => {
  if (!newVal) {
    notify.close(modalNotifyId);
  }
});

watch(stage, newVal => {
  if (newVal === 'put' && order.value) {
    shownModalsByOrder.value[order.value.order_id] = true;
  }
});

const noProduct = async (value: number, suggest: Suggest) => {
  countWindow.hide();

  try {
    await useShelf2Box(props.order_id, {
      suggest_id: suggest.suggest_id,
      status: 'error',
      reason: {
        code: 'PRODUCT_ABSENT',
        count: suggest.count! - value,
      },
    });

    needBarcodeRequest.value = true;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Обработчик закрытия текущего саджеста
 */
const finishActiveSuggest = async (
  { count = 0, shelf_id }: Pick<Model, 'count'> & { shelf_id: string },
  suggest: Suggest,
) => {
  if (!suggest) return;
  const confirmed = await Notifications.confirmBottom({
    title: getSuggestFinishConfirmTitle(suggest, count, shelf_id),
    text:
      suggest.type === SuggestTypeEnum.box2shelf && !isOrderCancelled.value
        ? 'После возвращения стеллажа, товары сразу станут доступны для продажи'
        : '',
  });
  if (!confirmed) return;

  if (suggest.type === SuggestTypeEnum.shelf2box) {
    const result = await useShelf2Box(props.order_id, {
      suggest_id: suggest.suggest_id,
      count,
    });
    if (!result) return;
  } else {
    //если нужно выбрать другую полку
    if (suggest.shelf_id !== shelf_id) {
      const result = await useBox2Shelf(props.order_id, {
        suggest_id: suggest.suggest_id,
        status: 'error',
        reason: { code: 'LIKE_SHELF', shelf_id },
      });
      if (!result) return;
    }

    const result = await useBox2Shelf(props.order_id, {
      suggest_id: suggest.suggest_id,
      count,
    });

    if (!result) return;
  }

  suggestDetails.hide();
};

/**
 * Обработчик завершения задания
 */
const finishOrder = async () => {
  const confirm = await Notifications.confirmBottom({
    title: $gettext('Вы уверены, что переместили все товары?'),
  });
  if (!confirm) return;
  needBarcodeRequest.value = false;
  const result = await useEndOrder(props.order_id);

  if (result) {
    toHomePage();
  } else {
    needBarcodeRequest.value = true;
  }
};

const next_stage = async () => {
  const confirm = await Notifications.confirmBottom({
    title: $gettext('Перейти к размещению собранных товаров?'),
  });
  if (!confirm) return;
  if (stage.value === 'put') {
    return;
  }
  const { closeLoader, updateLoader } = showLoader(undefined, props.order_id);
  try {
    await ordersStore.signal({
      order_id: props.order_id,
      signal: 'next_stage',
    });

    if (stage.value === 'put') {
      closeLoader();
      return;
    }

    needBarcodeRequest.value = false;
    updateLoader($gettext('Ожидаем переход на стадию раскладки'), props.order_id);

    const waitForPutStage = useSubscribeOnOrder(props.order_id)(
      order => !order || (order.vars.stage as string) === 'put',
    );

    await Promise.race([sleep(7000), waitForPutStage]);

    if (waitingForRacks.value) {
      showRackOnTheWayModal();
    } else {
      await waitForPutStage;
    }

    closeLoader();
  } catch (error) {
    console.error(error);
    closeLoader();
  }
};
</script>
