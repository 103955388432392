<template>
  <PageLayout :order_id="order_id">
    <SuggestDetails
      v-if="suggestDetails.visible.value && suggestDetails.props.value"
      :suggest-id="suggestDetails.props.value.suggest_id"
      :suggest-prop="suggestDetails.props.value.virtual ? suggestDetails.props.value : undefined"
      :order-id="order_id"
      :barcode="scanBarcode"
      @cancel="suggestDetails.hide"
      @finish="val => finishActiveSuggest(val, suggestDetails.props.value)"
    />
    <ShareOrder v-else-if="order && shareOrder.visible.value" :order="order" @close="shareOrder.hide" />
    <SuggestsList
      v-else
      :order_id="order_id"
      :suggest-menu-config="suggestMenuConfig"
      @show-share-order="showShareOrder"
      @close="toHomePage"
      @finish-order="finishOrder"
      @open-details="openDetails"
    />
  </PageLayout>
</template>

<script setup lang="ts">
import { useSubscribeOnOrderStatus } from '@/fsd/data/utils/subscribeOnOrder';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { useHandleProductCode } from '@/fsd/entities/scanner';
import { useFilter } from '@/fsd/entities/suggest';
import { useCheck } from '@/fsd/entities/suggest/tools/useCheck';
import { checkContractor } from '@/fsd/features/order/utils/checkContractor';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { SuggestsList } from '@/fsd/widgets/acceptance';
import { useComponent } from '@/hooks/useComponent';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product, { TypeAccountingEnum } from '@/models/Product';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import AcceptanceOrder from '@/models/orders/AcceptanceOrder';
import { AcceptanceModeAttrEnum, OrderTargetEnum, RequestTypeAttrEnum } from '@/models/orders/BaseOrder';
import { AudioService } from '@/services/audio.service';
import { ModeService } from '@/services/mode.service';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import { $gettext, $ngettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import { QrActions } from '@/temp/services/qr-actions';
import { useLoader } from '@/ui/common/loader/useLoader';
import { MenuItemConfig } from '@/ui/common/menu/types';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import { getWeightToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import { checkConditions } from '@/utils/checkConditions';
import { AxiosError } from 'axios';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

const scanBarcode = ref<string | undefined>();

const props = defineProps<{ order_id: string }>();

interface RequestResult {
  product?: Product;
  findSuggest?: Suggest;
  childProduct?: Product;
}

const { showLoader } = useLoader();
const productsStore = useProducts();
const ordersStore = useOrders();
const userStore = useUser();
const shareOrder = useComponent();
const suggestDetails = useComponent<Suggest>();
const router = useRouter();
const { suggests, requestSuggests, completedSuggests } = useFilter(props.order_id);

const order = computed<AcceptanceOrder | undefined>(() => ordersStore.orderById(props.order_id) as AcceptanceOrder);

const { checkBarcode, findNeededProduct } = useHandleProductCode(props.order_id);

const isWeight = computed<boolean>(() => suggests.value.some(s => s.conditions.need_weight));

const isMayBeTrust = computed<boolean>(() => {
  return (
    order.value?.attr.request_type === RequestTypeAttrEnum.move_order &&
    requestSuggests.value.length === suggests.value.length &&
    !isWeight.value
  );
});

const isMustBeTrust = computed<boolean>(
  () => !!userStore.experimentByName(experiments.exp_lightman) && !!order.value?.attr.trust_code,
);

const onScanBarcode = async (barcode: string) => {
  // TODO это для обратной совместимости, в будущем надо будет серьезнее распилить этапы с подписью и без
  if (QrActions.isAction(barcode)) {
    logger('Получен баркод с подписью');
    barcode = await QrActions.trustAcceptance(barcode);
  }
  if (order.value && [order.value.attr.request_id, order.value.attr.trust_code].includes(barcode)) {
    if (order.value.attr.request_type === RequestTypeAttrEnum.move_order && completedSuggests.value.length) {
      Alerts.error($gettext('Невозможно завершить приемку доверительно, если уже есть принятые товары !'));
      return false;
    }
    await checkConfirmedTrustAcceptance(trustAcceptance);
    return false;
  }
  if (!ModeService.isProduction() && barcode === order.value?.attr.doc_number) {
    await checkConfirmedTrustAcceptance(trustAcceptance);
    return false;
  }
  if (isMustBeTrust.value) {
    Modal.show({ title: $gettext('Приемка разрешена только по QR коду') });
    return false;
  }
  return true;
};

const { needBarcodeRequest } = useRequestBarcode(async barcode => {
  const result: RequestResult = {};

  try {
    const next = await onScanBarcode(barcode);

    if (next) {
      const checkResults = await checkBarcode(barcode);
      const { product, childProduct, findSuggest } = await findNeededProduct(checkResults, barcode);
      result.product = product;
      result.childProduct = childProduct;
      result.findSuggest = findSuggest;
      if (!childProduct && product?.parent_id) result.childProduct = product;
    }
  } catch (e) {
    return true;
  }

  if (!result.findSuggest) {
    if (order.value?.attr.acceptance_mode !== AcceptanceModeAttrEnum.pre_check || !result.product) return true;

    // проверить возможность добавления продукта
    const canSendMoreProduct = await checkContractor(props.order_id, result.product.product_id);
    if (!canSendMoreProduct) {
      AudioService.playError();
      return true;
    }
    const virtualSuggest = new Suggest({
      order_id: props.order_id,
      product_id: result.product!.product_id,
      shelf_id: order.value.shelves[0],
      count: 0,
      conditions: {
        all: true,
        max_count: false,
        need_valid: true,
      },
      virtual: true,
    });
    await suggestDetails.asyncShow(virtualSuggest);
    return true;
    // открыть типичное окно для приемки,спросить кол-во, добавить саджест в приемку, вернуть плользователя на главную, не блочить ему сценарий.
  }

  scanBarcode.value = barcode;
  const suggest = result.findSuggest!;
  //если нет закрытых саджестов и есть поле request_type==='move_order спрашиваем точно ли кладовщик не хочет принимать доверительно
  if (isMayBeTrust.value) {
    const confirmed = await Modal.show({
      title: $gettext('Эта приемка должна быть выполнена доверительно'),
      text: $gettext(
        'Вы хотите принять все товары не доверительно – это действие необратимо. Вы уверены, что хотите продолжить?',
      ),
      btnPosition: ButtonPositionsEnum.horizontal,
      confirmBtnTitle: $gettext('Продолжить'),
      closeBtnTitle: $gettext('Назад'),
    });
    if (confirmed) await suggestDetails.asyncShow(suggest);
    return true;
  }
  if (
    result.childProduct?.type_accounting === TypeAccountingEnum.unit &&
    result.childProduct.parent_id &&
    suggest.conditions.need_weight
  ) {
    Modal.show({ title: $gettext('Сканируйте ШК с коробки') });
    return true;
  }
  await suggestDetails.asyncShow(suggest);
  return true;
});

const checkConfirmedTrustAcceptance = async (trustAcceptance: () => void): Promise<void> => {
  const confirmed = await Modal.show({
    title: $gettext('Вы хотите принять весь товар без проверки?'),
    text: $gettext(
      'Если вы подтвердите это действие, то весь товар будет принят автоматически и проверять его не потребуется',
    ),
    btnPosition: ButtonPositionsEnum.horizontal,
  });
  if (confirmed) trustAcceptance();
};

const toHomePage = () => {
  router.push({ name: 'home' });
};

const finishActiveSuggest = async (
  { count = 0, weight, date }: Pick<Model, 'count' | 'weight' | 'date'>,
  suggest: Suggest,
): Promise<void> => {
  if (!suggest) {
    Alerts.error($gettext('Произошла ошибка при выполнении задания'));
    logger.error('empty active suggest', {
      type: 'code',
      page: 'accceptance',
      method: 'finishActiveSuggest',
      message: 'empty active suggest',
    });
    return;
  }
  const activeProduct = suggest.product;

  if (count !== 0) {
    const getTitle = () => {
      if (activeProduct?.quants && activeProduct?.quants > 1 && activeProduct.quant_unit) {
        return $ngettext(
          'Вы уверены, что хотите принять %{count} упаковок товара (%{unit_count} %{unit})?',
          'Вы уверены, что хотите принять %{count} упаковок товара (%{unit_count} %{unit})?',
          count,
          {
            count: String(count),
            unit_count: String(activeProduct?.quants * count),
            unit: getQuantUnit(activeProduct?.quant_unit),
          },
        );
      }

      if (activeProduct?.type_accounting === TypeAccountingEnum.true_weight) {
        return $gettext('Вы уверены, что хотите принять %{weightPart} %{product}?', {
          weightPart: String(getWeightToView(count)),
          product: String(activeProduct?.title),
        });
      }
      return $gettext('Вы уверены, что хотите принять %{count} шт. %{product}?', {
        count: String(count),
        product: String(activeProduct?.title),
      });
    };
    const confirmed = await Notifications.confirmBottom({
      title: getTitle(),
    });
    if (!confirmed) return;
  } else {
    const confirmed = await Notifications.confirmBottom({
      title: $gettext('Вы уверены, что товар %{product} отсутствует?', {
        count: String(count),
        product: String(activeProduct?.title),
      }),
    });
    if (!confirmed) return;
  }

  if (count === suggest.result_count) {
    suggestDetails.hide();
    needBarcodeRequest.value = true;
    return;
  }
  if (suggest.virtual) {
    // особый случай для б2б и документов с pre_check
    await ordersStore.signal({
      order_id: props.order_id,
      signal: 'add_product',
      data: {
        product_id: suggest.product_id,
        valid: date,
        count,
      },
    });
  } else {
    const result = await useCheck(props.order_id, {
      suggest_id: suggest.suggest_id,
      count,
      weight,
      valid: date,
    });
    if (!result) return;
  }

  suggestDetails.hide();
  needBarcodeRequest.value = true;
};

const finishOrder = async (): Promise<void> => {
  const isFinishedConfirm = await Notifications.confirmBottom({
    title: $gettext('Вы уверены, что приняли все товары?'),
  });
  if (!isFinishedConfirm) return;
  needBarcodeRequest.value = false;
  const result = await useEndOrder(props.order_id);
  if (result) {
    toHomePage();
  } else {
    needBarcodeRequest.value = true;
  }
};

const trustAcceptance = async (): Promise<void> => {
  const { closeLoader } = showLoader($gettext('Отправляем запрос на доверительную приемку'), props.order_id);
  try {
    await ordersStore.signal({
      order_id: props.order_id,
      signal: 'acceptance_agree',
    });
    await useSubscribeOnOrderStatus(props.order_id)(s => {
      if (!s || s === OrderTargetEnum.complete) {
        Alerts.success($gettext('Доверительная приемка проведена'));
        toHomePage();
        return true;
      }
      return s === OrderTargetEnum.failed;
    });
    closeLoader();
  } catch (e: any) {
    closeLoader();
    if (!e.isAxiosError) return;
    const axiosError = e as AxiosError<any>;
    if (axiosError.response?.data.code === 'ER_SUGGEST_WEIGHT_REQUIRED') {
      Modal.show({
        title: $gettext('Невозможно принять доверительно'),
        text: $gettext('В приемке присутствуют весовые товары'),
      });
    }
  }
};

const showShareOrder = async (): Promise<void> => {
  needBarcodeRequest.value = false;
  await shareOrder.asyncShow();
  needBarcodeRequest.value = true;
};

const openDetails = async (suggest: Suggest) => {
  needBarcodeRequest.value = false;
  await suggestDetails.asyncShow(suggest);
  needBarcodeRequest.value = true;
};

const onNoProductClick = (suggest: Suggest) => {
  if (isMustBeTrust.value) {
    Modal.show({ title: $gettext('Приемка разрешена только по QR коду') });
    return;
  }
  if (suggest.conditions.need_weight) {
    finishActiveSuggest({ count: 0, weight: 0 }, suggest);
  } else {
    finishActiveSuggest({ count: 0 }, suggest);
  }
};

const suggestMenuConfig = (suggest: Suggest): MenuItemConfig[] => {
  return [
    {
      buttonText: $gettext('Отметить как отсутствующий'),
      onClick: () => {
        onNoProductClick(suggest);
      },
      dataTest: 'no-product btn',
      condition: () => {
        return (
          checkConditions(suggest, 'all', true) &&
          (suggest.status === SuggestStatusEnum.request || suggest.result_count! > 0)
        );
      },
    },
    {
      buttonText: $gettext('Принять товар'),
      onClick: async () => {
        if (isMustBeTrust.value) {
          Modal.show({ title: $gettext('Приемка разрешена только по QR коду') });
          return;
        }
        await openDetails(suggest);
      },
      dataTest: 'open-details btn',
      condition: () => {
        return (
          productsStore.productById(suggest.product_id)?.type_accounting === TypeAccountingEnum.true_weight &&
          userStore.experimentByName(experiments.exp_weighty_touch)
        );
      },
    },
  ];
};
</script>
