<template>
  <div data-test="suggest card">
    <div
      v-if="!uiState.isProductLoading"
      class="px-4 py-1"
      :class="{
        'is-rtl': isRTL,
        'cursor-pointer': needMenu,
      }"
      @click="openSuggestMenu"
    >
      <div class="rounded-2xl py-4 px-2 bg-semantic-bg-minor flex flex-row justify-between overflow-hidden">
        <div class="flex flex-row gap-1">
          <ImgCardList :img-src="imageSrc" :badges="statusBadge" />
          <div class="flex flex-col gap-3">
            <Body2 color="primary-text" class="font-medium" data-test="suggest-card title">
              {{ title }}
            </Body2>
            <div class="flex flex-wrap">
              <FragileBadge
                v-if="['acceptance', 'sale_stowage'].includes(order?.type) && product?.fragile"
                size="small"
              />
              <TrueMarkBadge
                v-if="['acceptance', 'sale_stowage'].includes(order?.type) && product?.true_mark"
                size="small"
              />
              <WeightBadge
                v-if="['acceptance', 'sale_stowage'].includes(order?.type) && product?.isTrueWeight"
                size="small"
              />
            </div>
            <Rows
              :suggest="suggest"
              :doc-type="docType"
              :order="order"
              :available-product="availableProduct || availableItem"
              :collected="collected"
            />
          </div>
        </div>
        <IconChevron v-if="needMenu" class="mr-2 mt-1" />
      </div>
    </div>
    <Teleport to="#root">
      <SuggestMenu
        v-if="suggestMenu.visible.value"
        :title="title"
        :subtitle="collectedCountSubtitle"
        :menu-config="suggestMenuConfig"
        @close="() => suggestMenu.executeAndHide($emit, 'close-suggest-menu')"
    /></Teleport>
  </div>
</template>

<script lang="ts">
import { FragileBadge, StatusBadgeConfig, StatusTypeEnum, TrueMarkBadge, WeightBadge } from '@/fsd/entities/badges';
import { ImgCardList } from '@/fsd/shared/ui/imgCardList';
import { useComponent } from '@/hooks/useComponent';
import Item, { AvailableItem } from '@/models/Item';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import { useItems } from '@/store/modules/items';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { DocTypes } from '@/temp/constants/translations/types';
import IconChevron from '@/temp/icons/icon-chevron.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { AvailableProduct } from '@/types/product';
import SuggestMenu from '@/ui/common/menu/suggest-menu.vue';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import Rows from '@/ui/common/suggest-card/data-rows/rows.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import { PropType, defineComponent } from 'vue';

interface Data {
  uiState: {
    isProductLoading: boolean;
  };
}
// тип отображаемой сущности
type SuggestCardType = 'suggest' | 'item' | 'product';

// глобально, это место, где мы показываем поле. может быть типом документа или каким то специфичным названием.
// в зависимости от этого поля мы определяем, что должно быть показано и с каким лейблом.

export default defineComponent({
  components: {
    IconChevron,
    ImgCardList,
    Body2,
    WeightBadge,
    TrueMarkBadge,
    FragileBadge,
    SuggestMenu,
    Rows,
  },
  props: {
    order: {
      type: Object as PropType<BaseOrder>,
      default: undefined,
    },
    cardType: {
      type: String as PropType<SuggestCardType>,
      default: 'suggest',
    },
    docType: {
      type: String as PropType<DocTypes>,
      default: undefined,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: undefined,
    },
    availableProduct: {
      type: Object as PropType<AvailableProduct>,
      default: undefined,
    },
    availableItem: {
      type: Object as PropType<AvailableItem>,
      default: undefined,
    },
    needMenu: {
      type: Boolean,
      default: false,
    },
    collected: {
      type: Number,
      default: 0,
    },
    suggestMenuConfig: {
      type: Array as PropType<MenuItemConfig[]>,
      default: () => [],
    },
  },
  emits: ['open-suggest-menu', 'close-suggest-menu'],
  setup() {
    const shelvesStore = useShelves();
    const itemsStore = useItems();
    const productsStore = useProducts();
    const userStore = useUser();
    const suggestMenu = useComponent();

    return { shelvesStore, itemsStore, productsStore, userStore, suggestMenu };
  },
  data(): Data {
    return {
      uiState: {
        isProductLoading: true,
      },
    };
  },
  computed: {
    isRTL(): boolean {
      return this.userStore.isRTL;
    },
    title(): string {
      if (this.cardType === 'item') {
        return this.item?.title || '';
      }
      if (this.cardType === 'product') {
        return this.product?.title || '';
      }
      if (this.suggest?.vars?.mode === 'item') {
        return this.item?.title || '';
      }
      return this.product?.title || '';
    },
    isGrayscale(): boolean {
      if (!this.docType || ['pack', 'check_more', 'inventory_check_more'].includes(this.docType)) {
        return false;
      }
      return this.isComplete;
    },
    showCompleteStatus(): boolean {
      if (this.suggest && this.suggest.status === 'done' && this.docType) {
        return [
          'stowage',
          'sale_stowage',
          'weight_stowage',
          'acceptance',
          'refund',
          'part_refund',
          'writeoff_prepare_day',
          'check_valid_regular',
          'check_valid_short',
          'writeoff',
          'inventory_check_product_on_shelf',
          'shipment',
          'hand_move',
          'kitchen_provision',
          'robot_provision',
          'visual_control',
          'shipment_rollback',
          'check',
          'repacking',
        ].includes(this.docType);
      }
      return false;
    },
    isComplete(): boolean {
      if (this.suggest) {
        return this.suggest.status === 'done';
      }
      return false;
    },
    product(): Product | undefined {
      if (this.cardType === 'item') {
        return undefined;
      }
      if (this.availableProduct) {
        return this.productsStore.productById(this.availableProduct.product_id);
      }
      return this.productsStore.productById(this.suggest?.product_id);
    },
    item(): Item | undefined {
      if (this.suggest?.vars?.mode === 'item') {
        return this.itemsStore.itemById(this.suggest.product_id);
      }
      if (this.cardType === 'item') {
        return this.itemsStore.itemById(this.availableItem?.product_id);
      }
      return undefined;
    },
    isControl(): boolean {
      if (!this.order) {
        return false;
      }
      return ['writeoff_prepare_day', 'check_valid_regular', 'check_valid_short'].includes(this.order.type);
    },
    componentCount(): number {
      if (this.availableProduct) {
        if (this.availableProduct.is_components) {
          return Math.ceil(this.availableProduct.count / this.availableProduct.quants);
        }
        return this.availableProduct.count;
      }
      return 0;
    },
    statusBadge(): StatusBadgeConfig[] {
      if (!this.suggest || !this.order || !this.showCompleteStatus) return [];
      if (this.docType === 'sale_stowage' && this.suggest?.result_count && this.suggest.vars.stage !== 'trash') {
        return [
          {
            text: this.$gettext('В продаже'),
            type: StatusTypeEnum.processing,
          },
        ];
      }
      //Показываем в КСГ зеленую плашку на готовых заданиях
      if ((this.isControl || ['visual_control', 'repacking'].includes(this.order!.type)) && this.suggest.isDone) {
        return [
          {
            text: this.$gettext('Готово'),
            type: StatusTypeEnum.complete,
          },
        ];
      }

      if (this.suggest.result_count === 0 && this.suggest.isDone) {
        return [
          {
            text: this.$gettext('Нет'),
            type: StatusTypeEnum.error,
          },
        ];
      }
      if (this.suggest.isDone && this.suggest.result_count === this.suggest.count) {
        return [
          {
            text: this.$gettext('Готово'),
            type: StatusTypeEnum.complete,
          },
        ];
      }
      if (
        this.suggest.result_count &&
        this.suggest.result_count > 0 &&
        this.suggest.result_count < this.suggest.count!
      ) {
        return [
          {
            text: this.$gettext('Почти'),
            type: StatusTypeEnum.waiting,
          },
        ];
      }

      return [
        {
          text: this.$gettext('Готово'),
          type: StatusTypeEnum.complete,
        },
      ];
    },
    imageSrc(): string {
      if (this.cardType === 'item') {
        if (this.item) return this.item.imgSrc;
      }
      if (this.cardType === 'product') {
        return this.product?.images[0] || 'empty';
      }
      // @ts-expect-error pinia
      return this.suggest?.imageSrc;
    },
    collectedCountSubtitle(): string {
      return $gettext('Количество %{n} из %{all} шт.', {
        n: String(this.suggest?.result_count || this.collected),
        all: String(this.suggest?.count),
      });
    },
  },
  async mounted(): Promise<void> {
    if (this.cardType === 'item') {
      if (this.availableItem) {
        this.uiState.isProductLoading = false;
      }
      return;
    }
    if (!this.suggest) {
      if (this.availableProduct) {
        this.uiState.isProductLoading = false;
      }
      return;
    }
    if (!this.suggest.vars) {
      if (this.suggest.product_id) {
        await this.productsStore.getProductById(this.suggest.product_id);
      }
      this.uiState.isProductLoading = false;
      return;
    }
    if (!this.suggest.vars?.mode || this.suggest.vars.mode === 'product') {
      const product = await this.productsStore.getProductById(this.suggest.product_id);
      // грузим available, нужно для отображения в поле кол-ва
      if (this.docType === 'check') {
        try {
          // @ts-expect-error pinia product был any
          await this.productsStore.fetchAvailable(product.product_id);
          await this.shelvesStore.fetchAvailable(this.suggest.shelf_id);
        } catch (error) {
          console.error(error);
        }
        const available = this.productsStore.availableByProductId(product?.product_id);
        if (!available) {
          this.uiState.isProductLoading = false;
          return;
        }
        const shelvesId = available.map(item => item.shelf_id);
        if (shelvesId.length === 0) {
          this.uiState.isProductLoading = false;
          return;
        }
        await this.shelvesStore.getShelvesByIds(shelvesId);
        this.uiState.isProductLoading = false;
      }
    } else {
      await this.itemsStore.getItemById(this.suggest.product_id);
    }
    if (this.suggest.shelf_id) {
      await this.shelvesStore.getShelfById(this.suggest.shelf_id);
    }
    this.uiState.isProductLoading = false;
  },
  methods: {
    openSuggestMenu(): void {
      if (this.needMenu) {
        this.suggestMenu.show();
        this.$emit('open-suggest-menu');
      }
    },
  },
});
</script>
