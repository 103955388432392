<template>
  <div class="px-4 pb-4 pt-2">
    <div class="mb-2">
      <Hint>{{ hintText }}</Hint>
    </div>
    <Counter
      v-if="!keyboard"
      :value="initialValue"
      :disabled="disabled"
      :min="min"
      :max="max"
      :product_id="productId"
      :icon-key="iconKey"
      @confirm="onInputCount"
    />
    <Keyboard v-if="keyboard" :min="min" :max="max" :product-id="productId" @input="onKeyboardInput" />
  </div>
</template>
<script lang="ts">
import Counter from '@/fsd/entities/counter';
import Product from '@/models/Product';
import { useProducts } from '@/store/modules/products';
import Hint from '@/ui/common/hint/hint.vue';
import Keyboard from '@/ui/common/keyboard/keyboard.vue';
import { defineComponent } from 'vue';

// компонет возвращает кол-во в пачках для обычных полок и в квантах для компонентных
export default defineComponent({
  components: {
    Counter,
    Hint,
    Keyboard,
  },
  props: {
    isCounterNext: {
      type: Boolean,
      default: false,
    },
    keyboard: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      type: Number,
      default: undefined,
    },
    productId: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: undefined,
    },
    min: {
      type: Number,
      default: undefined,
    },
  },
  emits: ['confirmInput'],
  setup() {
    const productsStore = useProducts();

    return { productsStore };
  },
  computed: {
    product(): Product {
      return this.productsStore.productById(this.productId)!;
    },
    iconKey(): string {
      if (this.isCounterNext) {
        return 'next';
      }
      return 'check';
    },
  },
  methods: {
    async onInputCount(value: number): Promise<void> {
      this.$emit('confirmInput', value);
    },
    async onKeyboardInput(value: number | string | null): Promise<void> {
      if (typeof value === 'number') {
        await this.onInputCount(value);
      }
    },
  },
});
</script>
