<template>
  <Blur>
    <Layout data-test="rack validation">
      <template #default>
        <Bar is-fixed @close-click="emits('close-click')" />
        <div class="h-full flex flex-col items-center p-4 pt-[80px]">
          <Title2 class="font-bold mb-4">
            {{
              $gettext('Отсканируйте любую ячейку на станции %{rackLocation} из робозоны', {
                rackLocation,
              })
            }}
          </Title2>
          <Body2 class="mb-2">
            <template v-if="isProvisionOrder">
              {{ $gettext('После этого вы сможете разместить товары на стеллажах в робозоне') }}
            </template>
            <template v-else>
              <div class="mb-2">
                {{ $gettext('Дальше этот заказ нужно собирать со стеллажей, которые будут подвозиться в робозону') }}
              </div>
              <div>
                {{
                  $gettext('Чтобы быстро находить товар, который нужно собрать со стеллажа, отсканируйте любую ячейку.')
                }}
              </div>
            </template>
          </Body2>
          <div class="pt-4">
            <img src="./img/shelf.png" />
          </div>
        </div>
      </template>
    </Layout>
  </Blur>
</template>

<script setup lang="ts">
import IconRobotRounded from '@/fsd/shared/icons/robot/icon-robot-rounded.vue';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Shelf from '@/models/Shelf';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import { useShelves } from '@/store/modules/shelves';
import { $gettext } from '@/temp/plugins/gettext';
import Blur from '@/temp/ui/blur/blur.vue';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import { computed, defineEmits, defineProps, markRaw, onMounted } from 'vue';

interface ShelfValidationProps {
  suggests: Suggest[];
  order?: BaseOrder;
}

const props = defineProps<ShelfValidationProps>();

const emits = defineEmits<{
  (e: 'validated', shelf?: Shelf): void;
  (e: 'close-click'): void;
}>();

/**
 * Список незавершенных саджестов из робозоны
 */
const suggestsForCollect = computed((): Suggest[] => {
  return props.suggests.filter(
    suggest =>
      suggest.isRobozone &&
      (suggest.status === SuggestStatusEnum.request || suggest.status === SuggestStatusEnum.blocked),
  );
});

/**
 * Текущий незавершенный саджест из робозоны
 */
const activeSuggest = computed((): Suggest | undefined => {
  if (!suggestsForCollect.value.length) {
    return;
  }

  return suggestsForCollect.value[0];
});

/**
 * Станция на которую приехал|приедет стеллаж в робозоне
 */
const rackLocation = computed((): string => {
  return activeSuggest.value?.vars.rack_lock ?? '';
});

/**
 * True, если стеллаж приехал на станцию.
 */
const isRackArrived = computed((): boolean => {
  return activeSuggest.value?.status !== SuggestStatusEnum.blocked;
});

/**
 * True, если ордер на пополнение робозоны
 */
const isProvisionOrder = computed((): boolean => {
  return props.order?.type === OrderTypeEnum.robot_provision;
});

/**
 * Показывает модальное окно о том, что стеллажи еще не приехали на станцию
 */
const showRackOnTheWayModal = async () => {
  await Modal.show({
    title: $gettext('Роботы ещё везут товары из заказа %{orderNum}', {
      orderNum: props.order?.orderNumberForView ?? '',
    }),
    text:
      $gettext(
        'Заказ на паузе, он не влияет на вашу скорость сборки. Упакуйте то, что уже собрано и подпишите пакеты.',
      ) +
      '\n ' +
      $gettext('Сообщим, когда стеллажи будут на месте'),
    btnPosition: ButtonPositionsEnum.horizontal,
    confirmBtnTitle: $gettext('На главную'),
    closeBtnTitle: $gettext('Отмена'),
    onConfirm: () => emits('close-click'),
    component: markRaw(IconRobotRounded),
  });
};

onMounted(() => {
  if (!isRackArrived.value && !isProvisionOrder.value) {
    showRackOnTheWayModal();
  }
});

/**
 * Возвращает true, если в списке незавершенных саджестов есть саджест на эту полку
 */
const checkValidShelf = (scannedShelf: Shelf | undefined): boolean => {
  if (!suggestsForCollect.value.length || !activeSuggest.value) {
    return true;
  }

  return suggestsForCollect.value.some(suggest => suggest.shelf?.rack === scannedShelf?.rack);
};

useRequestBarcode(async barcode => {
  try {
    const shelf = await useShelves().getShelfByBarcode(barcode);

    if (checkValidShelf(shelf)) {
      emits('validated', shelf);
      return false;
    }
    throw new Error('Невалидный стеллаж');
  } catch (error) {
    console.error(error);
    Alerts.error($gettext('Отсканирована неверная полка'));
    return true;
  }
});
</script>
