<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :text="controlBarText"
        :caption="controlBarCaption"
        need-progress
        :progress-config="progressConfig"
        :total="filteredSuggests.length"
        :icons="{ left: filter === 'all' ? 'close' : 'back', right: 'menu' }"
        :menu-config="menuConfig"
        @close-click="onBarLeftBtnClick"
      />
    </template>
    <template #default>
      <div class="flex flex-col h-full">
        <Hint v-if="filteredSuggests.length > 0 && filter === 'all'" class="mx-4 my-2">
          {{ hintText }}
        </Hint>
        <SuggestCardContainer
          :doc-type="type"
          :order="order"
          :suggest-menu-config="suggestMenuConfig"
          :suggests="
            filteredSuggests.map(s => {
              s.need_menu = s.status === 'request' && s.type === 'shelf2box';
              return s;
            })
          "
          @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
          @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
        />
      </div>
      <SuggestDetails
        v-if="suggestDetails.visible.value"
        :order-id="order.order_id"
        :suggest-id="suggestDetails.props.value.suggest_id"
        @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
        @cancel-suggest="onCancelSuggest(suggestDetails.props.value)"
        @cancel="suggestDetails.hide"
      />
      <ScanShelf v-if="scanShelf.visible.value" @scanned="scanShelf.hide" />
      <ShareOrder v-if="order && shareOrder.visible.value" :order="order" @close="shareOrder.hide" />
    </template>
    <template #footer>
      <LayoutFooter>
        <FilterMenu v-if="filter === 'all' && stage === 'control'" :menu-config="filterMenuConfig" />
        <template v-if="filter === 'all'">
          <UiButton
            v-if="stage === 'control'"
            data-test="control footer next-stage-btn"
            :disabled="trashStageBtnDisabled"
            @click="nextStage"
          >
            {{ $gettext('Перейти к списанию') }}
          </UiButton>
          <UiButton
            v-if="stage === 'trash'"
            data-test="control footer finish-btn"
            :disabled="!allSuggestDone || order.estatus !== 'waiting'"
            @click="finishOrder"
          >
            {{ $gettext('Завершить') }}
          </UiButton>
        </template>
        <UiButton v-if="filter !== 'all'" background-color="secondary" @click="setFilter('all')">
          {{ $gettext('Назад') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { useSubscribeOnOrder, useSubscribeOnOrderSignal } from '@/fsd/data/utils/subscribeOnOrder';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { getBox2ShelfSuggests, getDoneSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { useFilter } from '@/fsd/entities/suggest/tools/useFilter';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useDetachFromOrder } from '@/fsd/features/order/utils/useDetachFromOrder';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import ControlOrder, { ControlModeEnum } from '@/models/orders/ControlOrder';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { logger } from '@/temp/plugins/logs';
import { ISuggestError } from '@/temp/plugins/notification';
import Bar from '@/ui/common/bar/bar.vue';
import FilterMenu from '@/ui/common/filter-menu/filter-menu.vue';
import { FilterMenuItemConfig } from '@/ui/common/filter-menu/types';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScanShelf from '@/ui/common/scan-shelf/scan-shelf.vue';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import { default as SuggestDetails } from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import { checkConditions } from '@/utils/checkConditions';
import SuggestCardContainer from '@/views/common/suggest-card-container.vue';
import { AxiosError } from 'axios';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiState: {
    isShelfBarcodeSuccess: boolean;
  };
  uiStateNeedBarcodeRequest: boolean;
}

export default defineComponent({
  name: 'Control',
  components: {
    PageLayout,
    ScanShelf,
    LayoutFooter,
    SuggestCardContainer,
    Bar,
    Hint,
    SuggestDetails,
    UiButton,
    FilterMenu,
    ShareOrder,
  },
  mixins: [requestProductCode, requestBarcode],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const ordersStore = useOrders();
    const shelvesStore = useShelves();
    const userStore = useUser();
    const productsStore = useProducts();
    const shareOrder = useComponent();
    const suggestDetails = useComponent<Suggest>();
    const scanShelf = useComponent<void, Shelf>();
    const router = useRouter();
    const { detachBtn } = useDetachFromOrder(props.order_id);
    const { filter, setFilter, suggests, requestSuggests } = useFilter(props.order_id);

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      ordersStore,
      shelvesStore,
      userStore,
      productsStore,
      shareOrder,
      suggestDetails,
      scanShelf,
      router,
      detachBtn,
      filter,
      setFilter,
      suggests,
      requestSuggests,
    };
  },
  data(): Data {
    return {
      uiState: {
        isShelfBarcodeSuccess: false,
      },
      uiStateNeedBarcodeRequest: true,
    };
  },
  computed: {
    order(): ControlOrder {
      return this.ordersStore.orderById(this.order_id) as ControlOrder;
    },
    menuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];
      menuConfig.push(this.detachBtn);

      const shareOrderBtn: MenuItemConfig = {
        buttonText: this.$gettext('Разделить задание'),
        onClick: () => this.showShareOrder(),
        condition: () => this.userStore.experimentByName(experiments.exp_tsd_companion),
      };
      menuConfig.push(shareOrderBtn);

      return menuConfig;
    },
    filteredSuggests(): Suggest[] {
      switch (this.filter) {
        case 'all':
          return [...this.box, ...this.required];
        case 'done':
          return this.box;
        case 'request':
          return this.required;
        case 'part':
          return this.partiallyCompletedSuggests;
        case 'noProduct':
          return this.noProductSuggests;
      }
      return this.suggests;
    },
    fullCompletedSuggests(): Suggest[] {
      return this.box.filter(s => s.count === s.result_count);
    },
    partiallyCompletedSuggests(): Suggest[] {
      return this.box.filter(s => s.count !== s.result_count && s.result_count !== 0);
    },
    noProductSuggests(): Suggest[] {
      return this.box.filter(s => s.result_count === 0);
    },
    doneSuggests(): Suggest[] {
      return getDoneSuggests(this.suggests);
    },
    allSuggestDone(): boolean {
      return this.requestSuggests.length === 0;
    },
    required(): Suggest[] {
      if (this.order.target === 'canceled') {
        return getBox2ShelfSuggests(this.requestSuggests);
      }
      if (this.stage === 'control') {
        return this.requestSuggests.filter(
          suggest => suggest.type === 'shelf2box' || (suggest.type === 'box2shelf' && suggest.vars.stage === 'store'),
        );
      }
      return this.requestSuggests.filter(suggest => suggest.type === 'box2shelf' && suggest.vars.stage !== 'store');
    },
    box(): Suggest[] {
      if (!this.order) return [];
      if (this.order.target === 'canceled') {
        return getBox2ShelfSuggests(this.doneSuggests);
      }
      if (this.stage === 'control') {
        return this.doneSuggests.filter(
          suggest => suggest.type === 'shelf2box' || (suggest.type === 'box2shelf' && suggest.vars.stage === 'store'),
        );
      }
      return this.doneSuggests.filter(suggest => suggest.type === 'box2shelf' && suggest.vars.stage !== 'store');
    },
    trashShelfId(): string {
      const suggest = this.suggests.find(suggest => suggest.type === 'box2shelf' && suggest.vars.stage !== 'store');
      if (!suggest) {
        return '';
      }
      return suggest.shelf_id;
    },
    trashShelf() {
      return this.shelvesStore.shelfById(this.trashShelfId);
    },
    hintText(): string {
      if (!this.order || !this.order.suggests) return '';

      if (this.order.estatus === 'waiting' && this.order.suggests.length === 0) {
        return this.$gettext('Заданий нет, можете завершить КСГ');
      }
      if (this.stage === 'trash' && this.box2ShelfSuggests.length === 0) {
        return this.$gettext('Завершите задание');
      }
      if (this.stage === 'trash' && this.uiState.isShelfBarcodeSuccess) {
        return this.$gettext('Разместите товары на полке');
      }
      if (this.stage === 'trash') {
        switch (this.mode) {
          case ControlModeEnum.store2trash:
          case ControlModeEnum.markdown2trash:
          case ControlModeEnum.review2trash:
            return this.$gettext('Сканируйте полку списания');
          case ControlModeEnum.store2review:
            return this.$gettext('Сканируйте полку разбора');
          case ControlModeEnum.eatToday2markdown:
          case ControlModeEnum.store2markdown:
            return this.$gettext('Сканируйте полку распродажи');
        }
      }
      return this.$gettext('Отсканируйте товар');
    },
    type(): string {
      if (this.order) {
        return this.order.type;
      }
      return 'check_valid_regular';
    },
    box2ShelfSuggests(): Suggest[] {
      return this.suggests.filter(suggest => suggest.type === 'box2shelf' && suggest.status !== 'done');
    },
    controlBarText(): string {
      switch (this.filter) {
        case 'done':
        case 'request':
        case 'part':
        case 'noProduct':
          if (this.filteredSuggests.length) {
            return this.$ngettext('%{filtered} товар', '%{filtered} товаров', this.filteredSuggests.length, {
              filtered: String(this.filteredSuggests.length),
            });
          } else {
            return '';
          }
      }
      switch (this.mode) {
        case ControlModeEnum.store2trash:
        case ControlModeEnum.markdown2trash:
        case ControlModeEnum.review2trash:
          return this.$gettext('Контроль срока годности');
        case ControlModeEnum.store2review:
          return this.$gettext('Контроль товаров для возврата');
        case ControlModeEnum.store2markdown:
          return this.$gettext('Контроль товаров для распродажи');
        case ControlModeEnum.eatToday2markdown:
          return this.$gettext('КСГ "Годен сегодня"');
        default:
          return this.$gettext('Контроль срока годности');
      }
    },
    controlBarCaption(): string {
      switch (this.filter) {
        case 'done':
          return this.$gettext('Товары со статусом “Готово”');
        case 'request':
          return this.$gettext('Не отсканированные товары');
        case 'part':
          return this.$gettext('Товары со статусом “Почти”');
        case 'noProduct':
          return this.$gettext('Товары со статусом “Нет”');
      }
      if (this.stage === 'control') return this.$gettext('Отбор');
      // this.stage === trash
      switch (this.mode) {
        case ControlModeEnum.store2trash:
        case ControlModeEnum.markdown2trash:
        case ControlModeEnum.review2trash:
          return this.$gettext('Списание');
        case ControlModeEnum.store2review:
          return this.$gettext('Возврат');
        case ControlModeEnum.eatToday2markdown:
        case ControlModeEnum.store2markdown:
          return this.$gettext('Уценка');
        default:
          return '';
      }
    },
    filterMenuConfig(): FilterMenuItemConfig[] {
      return [
        {
          buttonText: this.$gettext('Готово'),
          color: 'green',
          count: this.fullCompletedSuggests.length,
          onClick: () => {
            this.setFilter('done');
          },
        },
        {
          buttonText: this.$gettext('Почти'),
          color: 'orange',
          count: this.partiallyCompletedSuggests.length,
          onClick: () => {
            this.setFilter('part');
          },
        },
        {
          buttonText: this.$gettext('Нет'),
          color: 'warning',
          count: this.noProductSuggests.length,
          onClick: () => this.setFilter('noProduct'),
        },
        {
          buttonText: this.$gettext('Не отсканированные'),
          color: 'secondary',
          count: this.required.length,
          onClick: () => this.setFilter('request'),
        },
      ];
    },
    progressConfig(): any {
      if (!this.order) return;
      return [
        {
          count: this.box.length,
          color: 'green',
        },
      ];
    },
    suggestMenuConfig(): (suggest: Suggest) => MenuItemConfig[] {
      return suggest => {
        const menuConfig: MenuItemConfig[] = [];
        const noProductBtn: MenuItemConfig = {
          buttonText: this.$gettext('Отметить как отсутствующий'),
          onClick: () => {
            this.finishActiveSuggest({ count: 0 }, suggest);
          },
          dataTest: 'no-product btn',
          condition: () => {
            return (
              checkConditions(suggest, 'all', true) && suggest.status === 'request' && suggest.type === 'shelf2box'
            );
          },
        };
        menuConfig.push(noProductBtn);

        return menuConfig;
      };
    },
    stage(): 'control' | 'trash' {
      if (this.order.vars.suggests_write_off) {
        return 'trash';
      }
      return 'control';
    },
    mode(): ControlModeEnum {
      return this.order.vars.mode;
    },
    trashStageBtnDisabled(): boolean {
      const permit = this.userStore.permitByName('order_signals');
      return this.required.length > 0 || !permit || !(Array.isArray(permit) && permit.includes('next_stage'));
    },
    suggestById(): (suggest_id: string) => Suggest | undefined {
      return suggest_id => {
        return this.suggests.find(suggest => suggest.suggest_id === suggest_id);
      };
    },
    suggestsByProductId(): (product_id: string) => Suggest[] {
      return product_id => {
        return this.suggests.filter(s => s.product_id === product_id);
      };
    },
    // возвращает саджесты с этапа контроля. обычно это shelf2box . но в случае, если товар нужен для заказа это будет box2shelf.
    // для отмененного документа это всегда только box2shelf
    controlStageSuggestsByProductId(): (product_id: string) => Suggest[] {
      return product_id => {
        if (!this.order) return [];
        return this.suggestsByProductId(product_id).filter(s => {
          if (this.order.target === 'canceled') {
            return s.type === 'box2shelf';
          }
          return s.type === 'shelf2box' || (s.type === 'box2shelf' && s.vars.stage === 'store');
        });
      };
    },
  },
  watch: {
    box2ShelfSuggests: {
      handler() {
        this.uiState.isShelfBarcodeSuccess = this.box2ShelfSuggests.length === 0;
      },
      deep: true,
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const onScanBarcode = async barcode => {
        if (!this.order || !this.order.suggests) return false;
        if (this.stage === 'trash') {
          await this.handleTrashShelfScan(barcode);
          return false;
        }
        return true;
      };
      const { product } = await this.requestProductCode({ checkSuggests: true, onScanBarcode });
      if (!product) return true;
      return await this.selectProduct(product);
    },
    async handleTrashShelfScan(barcode: string): Promise<void> {
      if (!this.trashShelf) {
        return;
      }
      const shelf = await this.shelvesStore.getShelfByBarcode(barcode);
      if (!shelf) {
        this.$alert.error(this.$gettext('Не удалось отсканировать баркод, попробуйте еще раз'));
        return;
      }
      let valid = false;
      switch (this.mode) {
        case ControlModeEnum.store2trash:
        case ControlModeEnum.markdown2trash:
        case ControlModeEnum.review2trash:
          valid = barcode === this.trashShelf.barcode;
          break;
        case ControlModeEnum.store2review:
          valid = shelf.type === 'review';
          break;
        case ControlModeEnum.store2markdown:
        case ControlModeEnum.eatToday2markdown: {
          valid = shelf.type === 'markdown';
          break;
        }
      }
      if (valid) {
        await this.completeFinalStage();
      } else {
        this.$alert.error(this.$gettext('Отсканирована неверная полка'));
      }
    },
    onBarLeftBtnClick(): void {
      if (this.filter === 'all') {
        this.router.push({ name: 'home' });
      } else {
        this.setFilter('all');
      }
    },
    async finishActiveSuggest({ count = 0, date }: Pick<Model, 'count' | 'date'>, suggest: Suggest): Promise<void> {
      if (!this.order) return;
      if (this.stage === 'trash') {
        this.suggestDetails.hide();
        this.$alert.error(this.$gettext('Невозможно отредактировать задание, документ находится на этапе списания'));
        return;
      }

      if (suggest.type === 'shelf2box') {
        const result = await useShelf2Box(this.order_id, { suggest_id: suggest.suggest_id, count, valid: date });
        if (!result) return;
      } else {
        const result = await useBox2Shelf(this.order_id, { suggest_id: suggest.suggest_id, count });
        if (!result) return;
      }
      this.suggestDetails.hide();
    },
    async onCancelSuggest(suggest: Suggest): Promise<void> {
      if (this.stage === 'trash') {
        this.suggestDetails.hide();
        this.$alert.error(this.$gettext('Невозможно отредактировать задание, документ находится на этапе списания'));
        return;
      }
      try {
        await useShelf2Box(this.order_id, {
          suggest_id: suggest.suggest_id,
          status: 'cancel',
          reason: { code: 'CHANGE_COUNT_VALID' },
        });
      } catch (error) {
        const activeProduct = this.productsStore.productById(suggest.product_id);
        logger.error(error, { method: 'onCancelSuggest', type: 'control' });
        const response = error as AxiosError['response'];
        const majorErrorConfig: ISuggestError = {
          title: this.$gettext('Не удалось отправить информацию на сервер'),
          reason: '',
          body: '',
          onClose: () => {
            this.suggestDetails.hide();
          },
          onRepeat: () => {
            this.onCancelSuggest(suggest);
          },
          operation: this.$gettext('Контроль товаров'),
          products: [{ label: activeProduct!.title, count: suggest.count!.toString() }],
        };
        if (!response) {
          majorErrorConfig.reason = 'Network error';
        } else {
          majorErrorConfig.reason = response.status.toString();
          majorErrorConfig.body = response.data.message;
        }
        this.$notification.error.major.suggest(majorErrorConfig);
      }
    },
    async selectProduct(product): Promise<boolean> {
      const controlStageSuggests = this.controlStageSuggestsByProductId(product.product_id);

      if (controlStageSuggests.length === 1) {
        const shelf2BoxSuggest = controlStageSuggests[0];
        await this.suggestDetails.asyncShow(shelf2BoxSuggest);
        return true;
      }
      if (controlStageSuggests.length === 0) {
        this.$alert.error(this.$gettext('Этот товар уже списан!'));
        return true;
      }
      const shelvesLength = [...new Set(controlStageSuggests.map(s => s.shelf_id))].length;
      // Для выбора саджеста, если есть несколько, просим пользователя отсканировать полку, но если все саджесты на одну и ту же полку(взять и положить), это не имеет смысла. поэтому пропускаем этот этап
      let shelfSuggests = controlStageSuggests;
      if (shelvesLength > 1) {
        const shelf = await this.scanShelf.asyncShow();
        if (!shelf) return true;
        shelfSuggests = controlStageSuggests.filter(item => item.shelf_id === shelf.shelf_id);
      }
      if (shelfSuggests.length === 0) {
        this.$alert.error(this.$gettext('Отсканирована неверная полка'));
        return this.selectProduct(product);
      }
      if (shelfSuggests.length === 1) {
        await this.suggestDetails.asyncShow(shelfSuggests[0]);
        return true;
      }
      // на одну и ту же полку может быть несколько саджестов с одним продуктом (взять и положить)
      if (shelfSuggests.length > 1) {
        //если есть в статусе запрос, то берем его
        const shelfSuggest = shelfSuggests.find(item => item.status === 'request');
        if (shelfSuggest) {
          await this.suggestDetails.asyncShow(shelfSuggest);
          return true;
        } else {
          //иначе берем взять. его можно отменить и переделать)
          const shelf2box = shelfSuggests.find(s => s.type === 'shelf2box');
          if (!shelf2box) return true;
          await this.suggestDetails.asyncShow(shelf2box);
          return true;
        }
      }
      return true;
    },
    async finishOrder(): Promise<void> {
      const confirmed = await this.$notification.confirmBottom({
        title: this.$gettext('Вы уверены, что разместили все товары?'),
      });
      if (!confirmed) return;
      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.router.push({ name: 'home' });
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
    async showShareOrder(): Promise<void> {
      this.uiStateNeedBarcodeRequest = false;
      await this.shareOrder.asyncShow();
      this.uiStateNeedBarcodeRequest = true;
    },
    async nextStage(): Promise<void> {
      const confirmed = await this.$notification.confirmBottom({
        title: this.$gettext('Вы уверены, что проверили все товары?'),
      });
      if (!confirmed) return;
      const { closeLoader, updateLoader } = this.showLoader(undefined, this.order_id);
      try {
        await this.ordersStore.signal({
          order_id: this.order_id,
          signal: 'next_stage',
        });
        switch (this.mode) {
          case ControlModeEnum.store2markdown:
          case ControlModeEnum.eatToday2markdown:
            updateLoader(this.$gettext('Ожидаем перехода задания на этап уценки'), this.order_id);
            break;
          case ControlModeEnum.store2review:
            updateLoader(this.$gettext('Ожидаем перехода задания на этап разбора'), this.order_id);
            break;
          case ControlModeEnum.store2trash:
          case ControlModeEnum.markdown2trash:
          case ControlModeEnum.review2trash:
            updateLoader(this.$gettext('Ожидаем перехода задания на этап списания'), this.order_id);
            break;
        }
        await this.waitNextStage();
      } catch (error) {
        console.error(error);
      } finally {
        closeLoader();
      }
    },
    async completeFinalStage(): Promise<void> {
      let message = '';
      switch (this.mode) {
        case ControlModeEnum.store2markdown:
        case ControlModeEnum.eatToday2markdown:
          message = this.$gettext('Уцениваем товары');
          break;
        case ControlModeEnum.store2review:
          message = this.$gettext('Перемещаем товары');
          break;
        case ControlModeEnum.store2trash:
        case ControlModeEnum.markdown2trash:
        case ControlModeEnum.review2trash:
          message = this.$gettext('Списываем товары');
          break;
      }
      const { closeLoader } = this.showLoader(message);
      try {
        await this.ordersStore.signal({
          order_id: this.order.order_id,
          signal: 'complete_final_stage',
        });
        // ждем когда сигнал перейдет в done
        await useSubscribeOnOrderSignal(this.order_id)(s => {
          return Boolean(s?.done);
        }, 'complete_final_stage');
        //снимаем лоадер
      } catch (error) {
        console.error(error);
      } finally {
        closeLoader();
      }
    },
    async waitNextStage(): Promise<void> {
      if (this.order.vars.suggests_write_off) {
        return;
      }
      await useSubscribeOnOrder(this.order_id)(o => {
        if (!o) return true;
        return !!o.vars.suggests_write_off;
      });
    },
  },
});
</script>
