<template>
  <Overlay>
    <div class="bg-main pointer-events-auto h-full w-screen">
      <Wizard
        :caption="$gettext('Условия перевозки')"
        :steps-number="stepsNumber"
        :current-step-index="currentStepIndex"
        @close-click="goBack"
      >
        <ArrivalTime
          v-if="currentStep === 'arrival_time'"
          :order="order"
          :saved-date-and-time="arrivalDate"
          @done="saveArrivalData"
        />
        <TruckNumber v-if="currentStep === 'truck_number'" :order="order" @back="goBack" @done="saveTruckNumber" />
        <Temperature v-if="currentStep === 'temperature'" :order="order" @done="nextStep" />
        <EmptyTruck v-if="currentStep === 'empty_truck'" @done="nextStep" />
        <DepartureTime
          v-if="currentStep === 'departure_time'"
          :order-id="order.order_id"
          :arrival-date="arrivalDate"
          @done="sendTimeSignal"
        />
      </Wizard>
    </div>
  </Overlay>
</template>
<script lang="ts">
import Wizard from '@/fsd/entities/wizard/wizard.vue';
import ArrivalTime from '@/fsd/widgets/acceptance/CheckTransportConditons/condition-steps/ArrivalTime.vue';
import DepartureTime from '@/fsd/widgets/acceptance/CheckTransportConditons/condition-steps/DepartureTime.vue';
import EmptyTruck from '@/fsd/widgets/acceptance/CheckTransportConditons/condition-steps/EmptyTruck.vue';
import Temperature from '@/fsd/widgets/acceptance/CheckTransportConditons/condition-steps/Temperature.vue';
import TruckNumber from '@/fsd/widgets/acceptance/CheckTransportConditons/condition-steps/TruckNumber.vue';
import AcceptanceOrder from '@/models/orders/AcceptanceOrder';
import orderQueue from '@/services/queue/order-queue';
import { useOrders } from '@/store/modules/orders';
import { defaultSourceFormatLong } from '@/temp/constants/dateFormat';
import { logger } from '@/temp/plugins/logs';
import { useLoader } from '@/ui/common/loader/useLoader';
import Overlay from '@/ui/common/overlay/overlay.vue';
import { deleteItem, getItem, setItem } from '@/utils/localStorageHelper';
import dayjs from 'dayjs';
import { PropType, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

enum stepEnum {
  arrival_time = 'arrival_time',
  truck_number = 'truck_number',
  temperature = 'temperature',
  empty_truck = 'empty_truck',
  departure_time = 'departure_time',
}
interface Data {
  steps: Array<stepEnum>;
  currentStepIndex: number;
  arrivalDate: Date | undefined;
  truck_number: string;
}

export default defineComponent({
  name: 'CheckTransportConditions',
  components: {
    TruckNumber,
    EmptyTruck,
    Temperature,
    ArrivalTime,
    DepartureTime,
    Wizard,
    Overlay,
  },
  props: {
    order: {
      type: Object as PropType<AcceptanceOrder>,
      required: true,
    },
    needVehicleCheckin: {
      type: Boolean,
    },
    needTemperature: {
      type: Boolean,
    },
  },
  emits: ['close', 'done'],
  setup() {
    const { showLoader } = useLoader();
    const ordersStore = useOrders();
    const router = useRouter();

    return { showLoader, ordersStore, router };
  },
  data(): Data {
    return {
      steps: [],
      currentStepIndex: 0,
      arrivalDate: undefined,
      truck_number: '',
    };
  },
  computed: {
    stepsNumber(): number {
      return this.steps.length;
    },
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
  },
  mounted() {
    if (this.needVehicleCheckin) {
      this.steps.push(stepEnum.arrival_time);
      this.steps.push(stepEnum.truck_number);
      const savedDateAndTime = getItem('arrival_date', this.order.order_id);
      this.truck_number = getItem('truck_number', this.order.order_id) || '';
      this.arrivalDate = savedDateAndTime ? new Date(savedDateAndTime) : undefined;
    }
    if (this.needTemperature) {
      this.steps.push(stepEnum.temperature);
    }
    if (this.needVehicleCheckin) {
      this.steps.push(stepEnum.empty_truck);
      this.steps.push(stepEnum.departure_time);
    }
  },
  methods: {
    nextStep(): void {
      if (this.currentStepIndex >= this.steps.length - 1) {
        // это был последний шаг
        this.$emit('done');
        return;
      }
      this.currentStepIndex++;
    },
    goBack(): void {
      if (this.currentStepIndex === 0) this.$emit('close');
      else this.currentStepIndex--;
    },
    saveArrivalData(date: Date): void {
      this.arrivalDate = date;
      setItem('arrival_date', this.order.order_id, this.arrivalDate);
      this.nextStep();
    },
    saveTruckNumber(number: string): void {
      this.truck_number = number;
      setItem('truck_number', this.order.order_id, this.truck_number);
      this.nextStep();
    },
    async sendTimeSignal(departureDate: Date): Promise<void> {
      const { closeLoader } = this.showLoader();
      try {
        const resp = await this.ordersStore.signal({
          order_id: this.order.order_id,
          signal: 'stat',
          data: {
            truck_arrival_time: dayjs(this.arrivalDate).format(defaultSourceFormatLong),
            truck_departure_time: dayjs(departureDate).format(defaultSourceFormatLong),
            truck_number: this.truck_number,
          },
        });
        if (resp.status !== 200) {
          this.$alert.error(this.$gettext('Произошла ошибка при отправке сигнала'));
          return;
        }
        await orderQueue.load(this.order.order_id);
        deleteItem('arrival_date', this.order.order_id);
        deleteItem('truck_number', this.order.order_id);
        this.nextStep();
      } catch (e) {
        this.$alert.error(this.$gettext('Произошла ошибка при отправке сигнала'));
        logger.error(e, { method: 'signal', type: 'api', source: 'check-transport-conditions.vue' });
      } finally {
        closeLoader();
      }
    },
  },
});
</script>
