<template>
  <div class="flex justify-between" :class="{ 'flex-col': mode === 'vertical', 'is-rtl': isRTL }" :data-test="dataTest">
    <Body2 data-test="suggest-details row label" color="semantic-text-minor">
      {{ label }}
    </Body2>
    <Body2 data-test="suggest-details row value" :is-bold="isBold" :class="{ 'mt-1': mode === 'vertical' }">
      {{ value }}
    </Body2>
  </div>
</template>
<script lang="ts">
import { useUser } from '@/store/modules/user';
import Body2 from '@/ui/common/typo/body-2.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Body2 },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '-',
    },
    mode: {
      type: String,
      default: 'horizontal',
    },
    dataTest: {
      type: String,
      default: '',
    },
    isBold: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const userStore = useUser();

    return { userStore };
  },
  computed: {
    isRTL(): boolean {
      return this.userStore.isRTL;
    },
  },
});
</script>
