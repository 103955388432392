<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#FFA60D" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2946 12.8046C18.4978 10.7234 21.5022 10.7234 22.7054 12.8046L28.8516 23.4359C30.0561 25.5193 28.5526 28.125 26.1462 28.125L13.8538 28.125C11.4474 28.125 9.94394 25.5193 11.1484 23.4359L17.2946 12.8046ZM20.0001 16.25C20.5179 16.25 20.9376 16.6697 20.9376 17.1875V19.6875C20.9376 20.2053 20.5179 20.625 20.0001 20.625C19.4824 20.625 19.0626 20.2053 19.0626 19.6875V17.1875C19.0626 16.6697 19.4824 16.25 20.0001 16.25ZM21.2501 23.4375C21.2501 24.1278 20.6905 24.6875 20.0001 24.6875C19.3098 24.6875 18.7501 24.1278 18.7501 23.4375C18.7501 22.7471 19.3098 22.1875 20.0001 22.1875C20.6905 22.1875 21.2501 22.7471 21.2501 23.4375Z"
      fill="white"
    />
  </svg>
</template>
