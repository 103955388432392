<template>
  <CameraProContainer v-if="scannerStore.cameraProActive" @close="closeCamera" />
  <CameraProOpen v-else @click="openCamera" />
</template>

<script setup lang="ts">
import { closeCameraPro, openCameraPro } from '@/fsd/data/services/pro.service';
import { CameraProContainer, CameraProOpen } from '@/fsd/features/camera-pro';
import { useScanner } from '@/store/modules/scanner';

const scannerStore = useScanner();

const openCamera = () => {
  scannerStore.setCameraProActive(true);
  openCameraPro();
};

const closeCamera = () => {
  scannerStore.setCameraProActive(false);
  closeCameraPro();
};
</script>
