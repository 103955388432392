<template>
  <div
    class="rounded-2xl py-4 px-2 bg-semantic-bg-minor flex flex-row gap-3 overflow-hidden mx-4 my-1"
    :data-test="`suggest card`"
    :class="{ 'is-rlt': isRTL }"
  >
    <ImgCardList :img-src="item?.type || product?.images[0]" />
    <div class="flex flex-col gap-3">
      <Body2 color="primary-text" class="font-medium" data-test="suggest-card title">
        {{ title }}
      </Body2>
      <div class="flex flex-wrap">
        <FragileBadge v-if="order?.type === OrderTypeEnum.acceptance && product?.fragile" size="small" />
        <TrueMarkBadge v-if="order?.type === OrderTypeEnum.acceptance && product?.true_mark" size="small" />
        <WeightBadge v-if="order?.type === OrderTypeEnum.acceptance && product?.isTrueWeight" size="small" />
      </div>
      <Row
        v-if="product && requiredItem?.count"
        class="text-primary"
        :label="$gettext('Количество')"
        :value="countToView"
        data-test="suggest card count row"
      />
      <Row v-if="shelf" :label="$gettext('Полка')" :value="shelf.title" data-test="suggest card shelf row" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { FragileBadge, TrueMarkBadge, WeightBadge } from '@/fsd/entities/badges';
import { ImgCardList } from '@/fsd/shared/ui/imgCardList';
import Item, { ItemInRequired } from '@/models/Item';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import itemQueue from '@/services/queue/item-queue';
import productQueue from '@/services/queue/product-queue';
import shelfQueue from '@/services/queue/shelf-queue';
import { useItems } from '@/store/modules/items';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { ProductInRequired } from '@/types/product';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import Row from '@/ui/common/suggest-card/row.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import { computed, onMounted } from 'vue';

interface RequiredCardProps {
  requiredItem?: Suggest | ItemInRequired | ProductInRequired;
  order?: BaseOrder;
}

const { requiredItem, order } = defineProps<RequiredCardProps>();

const itemsStore = useItems();
const shelvesStore = useShelves();
const productsStore = useProducts();
const userStore = useUser();

const isRTL = computed<boolean>(() => userStore.isRTL);

const product = computed<Product | undefined>(() => {
  if (requiredItem && 'product_id' in requiredItem) {
    return productsStore.productById(requiredItem.product_id);
  }
  return undefined;
});

const item = computed<Item | undefined>(() => {
  if (requiredItem && 'item_id' in requiredItem) {
    return itemsStore.itemById(requiredItem.item_id);
  }
  return undefined;
});

const shelf = computed<Shelf | undefined>(() => {
  if (requiredItem && 'shelf_id' in requiredItem) {
    return shelvesStore.shelfById(requiredItem.shelf_id);
  }
  return undefined;
});

const title = computed<string>(() => {
  if (product.value) return product.value.title;

  if (item.value) return item.value.title;

  return '-';
});

const countToView = computed<string>(() => {
  if (!requiredItem?.count || !product.value) {
    return '';
  }
  return getCountToView({ count: requiredItem.count, type_accounting: product.value.type_accounting });
});

onMounted(() => {
  if (requiredItem && 'product_id' in requiredItem) {
    productQueue.load(requiredItem.product_id);
  }
  if (requiredItem && 'item_id' in requiredItem) {
    itemQueue.load(requiredItem.item_id);
  }
  if (requiredItem && 'shelf_id' in requiredItem && requiredItem.shelf_id) {
    shelfQueue.load(requiredItem.shelf_id);
  }
});
</script>
