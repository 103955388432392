import { useScanner } from '@/store/modules/scanner';
import { logger } from '@/temp/plugins/logs';

// scanner.service ожидает везде промисы, поэтому сейчас все методы являются промисами
// даже те, которые фактически выполняются синхронно
export class CameraProScanner {
  private readonly mode = 'cameraPro';
  private loggerInstance = console.log;
  private reject?: () => void;
  private resolve?: (value: string) => void;

  constructor() {
    this.loggerInstance = logger ?? console.log;
  }

  closeCamera() {
    useScanner().setCameraProActive(false);
  }

  qrHandler(barcodeRaw: string) {
    if (!this.resolve) {
      this.loggerInstance('Нет обработчика для barcode');
    }
    this.resolve?.(barcodeRaw);
    this.closeCamera();
  }

  async init() {
    this.loggerInstance(`Запуск режима ${this.mode}`);
    // @ts-expect-error
    if (!window.pro) {
      // @ts-expect-error
      window.pro = {};
    }
    // @ts-expect-error
    window.pro.qrHandler = this.qrHandler.bind(this);
    // @ts-expect-error
    window.pro.closeCamera = this.closeCamera.bind(this);
  }

  async destroy() {
    this.loggerInstance(`Выключение режима ${this.mode}`);
    this.reject?.();
  }

  // рендер компонента кнопки активации камеры
  scan() {
    return new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
