<template>
  <div class="flex flex-row gap-3" :class="{ 'is-rtl': isRTL }" :data-test="dataTest">
    <Caption1 color="semantic-text-minor" class="min-w-24" data-test="suggest row label"> {{ label }}</Caption1>
    <slot v-if="hasSlot" />
    <Caption1 v-else color="primary" data-test="suggest row value"> {{ value }} </Caption1>
  </div>
</template>
<script setup lang="ts">
import { useUser } from '@/store/modules/user';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { computed, useSlots } from 'vue';

interface RowProps {
  label: string;
  value?: number | string;
  dataTest?: string;
}

const { label, value = '-', dataTest = '' } = defineProps<RowProps>();

const userStore = useUser();
const slots = useSlots();

const isRTL = computed<boolean>(() => userStore.isRTL);

const hasSlot = computed<boolean>(() => !!slots['default']);
</script>
