import { Modal } from '@/fsd/shared/tools/modalNotification';
import { useComponent } from '@/hooks/useComponent';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useUser } from '@/store/modules/user';
import { $gettext } from '@/temp/plugins/gettext';
import { ComputedRef, Ref, computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

interface UseRobozoneCollectProps {
  order: ComputedRef<OrderOrder | undefined>;
  suggestsForCollect: ComputedRef<Suggest[]>;
  activeSuggest: ComputedRef<Suggest>;
  activeSuggestIndex: Ref<number, number>;
  hideScanBarcodeCard: Ref<boolean>;
}

/**
 * Composable для обработки заказов с позициями из робозоны
 * @param params
 */
export const useRobozoneCollect = ({
  order,
  suggestsForCollect,
  activeSuggest,
  activeSuggestIndex,
  hideScanBarcodeCard,
}: UseRobozoneCollectProps) => {
  const router = useRouter();
  const userStore = useUser();

  /**
   * True, если заказ отменен
   */
  const isOrderCancelled = computed((): boolean => {
    return order.value?.target === 'canceled' || order.value?.target === 'failed';
  });

  /**
   * Компонент валидации стеллажа
   */
  const rackValidation = useComponent<Suggest[], Shelf>();

  /**
   * Словарь результатов валидации робостеллажей
   */
  const rackValidationResults = ref<Record<string, boolean>>({});

  /**
   * True, если пользователь может начать собирать саджесты из робозоны
   */
  const canCollectRobozoneSuggests = computed((): boolean => {
    if (!order.value?.hasRobozoneSuggests) {
      return false;
    }

    const nonRobozoneSuggests = suggestsForCollect.value.filter(suggest => !suggest.isRobozone);
    const allNonRobozoneSuggestsHandled = nonRobozoneSuggests.every(suggest => suggest.status !== 'request');
    return allNonRobozoneSuggestsHandled;
  });

  /**
   * True, если нужно показать action валидации стеллажа
   */
  const needShowRackValidation = computed((): boolean => {
    const rack = activeSuggest.value?.shelf?.rack;

    return (
      canCollectRobozoneSuggests.value &&
      activeSuggest.value?.isRobozone &&
      rack != null &&
      !rackValidationResults.value[rack]
    );
  });

  /**
   * True, если заказ должен быть отменен по причине того, что хотя бы один стеллаж не доехал
   */
  const orderShouldBeCancelled = computed<boolean>(() => {
    const hasNotArrivedRack = suggestsForCollect.value.some(suggest => suggest.vars?.rack_move_failed);
    return hasNotArrivedRack && order.value?.target !== OrderTargetEnum.canceled;
  });

  watch(
    needShowRackValidation,
    async val => {
      if (!val || rackValidation.visible.value) return;

      try {
        hideScanBarcodeCard.value = true;
        const shelf = await rackValidation.asyncShow(suggestsForCollect.value);

        if (shelf?.rack != null) {
          rackValidationResults.value[shelf.rack] = true;
          const suggestIndexWithRack = suggestsForCollect.value.findIndex(
            suggest => suggest.shelf?.rack === shelf.rack,
          );
          activeSuggestIndex.value = suggestIndexWithRack;
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideScanBarcodeCard.value = false;
      }
    },
    { immediate: true },
  );

  watch(isOrderCancelled, newVal => {
    if (newVal && rackValidation.visible.value) {
      rackValidation.hide();
    }
  });

  watch(
    orderShouldBeCancelled,
    async val => {
      if (!val) return;
      await Modal.show({
        title: $gettext('Отмените заказ через поддержку'),
        text: $gettext('Что-то случилось и робот не сможет привезти стеллаж.'),
        confirmBtnTitle: $gettext('Обратиться в поддержку'),
      });
      userStore.setChatMetaData({
        order_id: order.value?.order_id!,
        doc_number: order.value?.external_id!,
      });
      router.push({ name: 'support' });
    },
    { immediate: true },
  );

  return { rackValidation };
};
