<template>
  <p class="px-[1px] w-[24px] text-center" :class="fpsIndicatorColorClass">{{ fps }}</p>
</template>

<script setup lang="ts">
import { logger } from '@/temp/plugins/logs';
import { useFps } from '@vueuse/core';
import { computed, watch } from 'vue';

const fps = useFps({ every: 120 });

const fpsIndicatorColorClass = computed<string>(() => {
  if (fps.value >= 40) {
    return 'text-green-normal';
  } else if (fps.value >= 20) {
    return 'text-yellow-dark';
  }

  return 'text-red-normal';
});

let sendedError = false;
watch(fps, val => {
  if (val <= 5 && !sendedError) {
    sendedError = true;
    logger.error('status/fps', {
      additional: {
        fps: val,
      },
    });
  }
  if (val > 20) {
    sendedError = false;
  }
});
</script>
