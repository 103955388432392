<template>
  <div class="flex items-center">
    <p class="w-[36px] text-center">{{ time }}</p>
    <p class="pl-1.5 text-right">{{ date }}</p>
    <div class="ml-1 flex justify-center">
      <slot name="extra" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import dayjs from 'dayjs';
import { onBeforeUnmount, ref } from 'vue';

const time = ref('');
const date = ref('');

const updateDateTim = () => {
  const currentDate = dayjs();
  time.value = currentDate.format('HH:mm');
  date.value = currentDate.format('DD MMM');
};
updateDateTim();
const timer = setInterval(updateDateTim, 1000);

onBeforeUnmount(() => {
  clearInterval(timer);
});
</script>
