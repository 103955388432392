import Product from '@/models/Product';
import { errorMessagesToIgnore } from '@/temp/constants';
import { defaultSourceFormat, getDefaultLocalFormat } from '@/temp/constants/dateFormat';
import { errorCodes } from '@/temp/constants/translations';
import { AxiosError } from 'axios';
import dayjs, { OpUnitType } from 'dayjs';

export const getErrorText = (error: AxiosError<any>, defaultText: string = ''): string => {
  if (!error) return defaultText;
  const data = error.response?.data;
  if (!data) return defaultText;

  if (data?.code) {
    const code = errorCodes[data.code] || data.code;
    return `${code} ${data.message}`;
  }
  if (data?.message) {
    return `${data.message} ${data.details?.message || data.details || ''}`;
  }
  return defaultText;
};
export const dialog = async resolve => {
  const createdForm = document.getElementById('dialog-container');

  if (!createdForm) {
    const form = document.createElement('form');
    const formClasses = [
      'absolute',
      'max-h-4',
      'leading-2',
      'top-[20px]',
      'inset-x-[64px]',
      'flex',
      'opacity-50',
      'z-[50000]',
      'gap-1',
    ];
    form.classList.add(...formClasses);
    form.id = 'dialog-container';

    form.innerHTML = `
        <input id="dialog-input" class="border bg-gray-200 border-solid rounded-md flex-1" placeholder="Enter barcode" />
        <button id="dialog-send-btn" class="uppercase flex items-center bg-accent rounded-md text-sm px-1 font-medium" type="submit">Send</button>
    `;

    form.onsubmit = e => {
      e.preventDefault();
      const input = form.getElementsByTagName('input')[0] as HTMLInputElement;
      resolve(safeParse<string>(`"${input.value}"`) || input.value);
      form.remove();
    };
    document.body.prepend(form);
  } else {
    const sendBtn = document.getElementById('dialog-send-btn')!;
    sendBtn.onclick = () => {
      const input = createdForm.getElementsByTagName('input')[0] as HTMLInputElement;
      resolve(safeParse<string>(`"${input.value}"`) || input.value);
      document.body.removeChild(createdForm!);
    };
  }
};

interface GetFormatDateOptions {
  mode?: 'productionDate' | 'expirationDate';
  product?: Product;
  sourceFormat?: string;
  targetFormat?: string;
  subtract?: {
    value: number;
    unit: OpUnitType;
  };
  add?: {
    value: number;
    unit: OpUnitType;
  };
}

export const getFormatDate = (date: string | null | undefined, options: GetFormatDateOptions = {}): string => {
  const defaultTargetFormat = getDefaultLocalFormat();
  if (!date || !dayjs(date, options.sourceFormat || defaultSourceFormat).isValid()) return '—';
  let parsedDate = dayjs(date, options.sourceFormat || defaultSourceFormat).tz();
  if (options.mode && options.product) {
    if (options.mode === 'productionDate') {
      parsedDate = parsedDate.subtract(options.product.valid || 0, 'day');
    }
  }
  if (options.subtract) {
    const { value, unit } = options.subtract;
    parsedDate = parsedDate.subtract(value, unit);
  }
  if (options.add) {
    const { value, unit } = options.add;
    parsedDate = parsedDate.add(value, unit);
  }
  return parsedDate.format(options.targetFormat || defaultTargetFormat);
};

export const formatOrderNumber = (rawNumber: string) => {
  const parts = rawNumber.split('-');
  // номер должен выглядить как
  // дата-{6 символов} (YYMMDD-XXXXXX) или дата-{3 символа}-{4 символа} (YYMMDD-XXX-XXXX)
  // но по факту может выглядеть как угодно ))
  /**
   * Пример:
   * Заказ: 220324-766-7908
   * Возврат заказа клиента: 220322-498-8222-4fa8
   * Проверка товара на полке: 220318-149786
   */
  if (parts.length === 1) {
    return rawNumber.slice(-6);
  }
  if (parts[parts.length - 1].length === 6) {
    return parts[parts.length - 1];
  } else if (
    (parts[parts.length - 2].length === 4 && parts[parts.length - 1].length === 3) ||
    parts[parts.length - 1].length === 4
  ) {
    return rawNumber.replace(/^\d{6,}-/, '');
  }
  return rawNumber.slice(-6);
};

export const setAdditional = (additional: Ya.Rum.Additional) => {
  if (!window.Ya?.Rum?.updateAdditional) return;
  window.Ya.Rum.updateAdditional(additional);
  let prev: Ya.Rum.Additional = {};
  try {
    prev = JSON.parse(window.Ya.Rum._vars['-additional']) || {};
  } catch (e) {
    // console.error(e);
  }
  const newAdditional = {
    ...prev,
    ...additional,
  };
  // в данном режиме additional не прорастает во вкладку Additionals
  // но остаются доступны для поиска по строке поиска через additional.[name] === ...
  window.Ya.Rum.setVars({
    '-additional': JSON.stringify(newAdditional),
  });
};

export const scrollToHint = (id, block: 'start' | 'end' = 'start') => {
  const el = document.querySelector(id);
  if (el) {
    el.scrollIntoView({ block });
  }
};

export const isHomePage = () => {
  return window.location.pathname === `/` || window.location.pathname === `/${process.env.VUE_APP_VERSION}/`;
};

export const errorShouldNotBeIgnored = (error: Error): boolean => {
  if (!error.message) return true;
  for (const message of errorMessagesToIgnore) {
    if (message.test(error.message)) return false;
  }
  return true;
};

export function safeParse<T>(str: string): T | null {
  try {
    return JSON.parse(str) as T;
  } catch (e) {
    console.log('try parse',str)
    console.error(e);
    return null;
  }
}
