<template>
  <Layout>
    <template v-if="!loading" #header>
      <Bar @close-click="toHomePage" />
    </template>
    <template #default>
      <div v-if="!loading" class="flex flex-col gap-2 pb-4">
        <ProductTitle :product_id="product_id" class="mx-4" />
        <ProductMainInfo :product_id="product_id" class="mx-4" />
        <template v-if="available?.length">
          <Separator />
          <ProductAvailable :product_id="product_id" class="mx-4" />
          <Separator />
          <ProductAvailableTotal :product_id="product_id" class="mx-4" />
        </template>
      </div>
      <div v-else class="h-full flex flex-col items-center justify-center">
        <LoaderIndicator class="mb-4" />
        <body1 class="mb-2">
          {{ $gettext('Загрузка') }}
        </body1>
        <caption1 class="mb-2">
          {{ $gettext('Пожалуйста, подождите') }}
        </caption1>
      </div>
    </template>
    <template v-if="!loading" #footer>
      <LayoutFooter>
        <Hint class="w-full">
          {{ $gettext('Отсканируйте нужную полку') }}
        </Hint>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script lang="ts" setup>
import { api } from '@/fsd/data/api/api.service';
import { useCheckProductResources } from '@/fsd/data/utils/checkResourse';
import { useSubscribeOnSuggests } from '@/fsd/data/utils/subscribeOnSuggests';
import { useRumPage } from '@/fsd/shared/metrics';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import ProductAvailable from '@/fsd/widgets/productCard/ProductAvailable.vue';
import ProductAvailableTotal from '@/fsd/widgets/productCard/ProductAvailableTotal.vue';
import ProductMainInfo from '@/fsd/widgets/productCard/ProductMainInfo.vue';
import ProductTitle from '@/fsd/widgets/productCard/ProductTitle.vue';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import BaseOrder from '@/models/orders/BaseOrder';
import { AudioService } from '@/services/audio.service';
import { OrderCheckRequest } from '@/services/requests';
import { OrderCheckResponse } from '@/services/response';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { shelfTypes } from '@/temp/constants/translations';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import LoaderIndicator from '@/ui/common/loader-indicator.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Separator from '@/ui/home/product-card/separator.vue';
import { AxiosResponse, isAxiosError } from 'axios';
import uuidv1 from 'uuid/v1';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  product_id: Product['product_id'];
  parent_id?: BaseOrder['order_id'];
}>();
const { product_id, parent_id } = props;

const router = useRouter();
// для создания пересчетов по распоряжению нужно перезагрузить остатки
const { loading } = useCheckProductResources(product_id, Boolean(parent_id));
const { showLoader } = useLoader();

useRumPage(loading);

useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader($gettext('Ищем полку по ШК'));
  try {
    await useShelves().getShelfByBarcode(barcode);
    closeLoader();
  } catch (error) {
    closeLoader();
    Alerts.error($gettext('Не найден штрихкод'));
    return true;
  }
  const shelf = useShelves().shelfByBarcode(barcode);
  if (!shelf) {
    Alerts.error($gettext('Не найден штрихкод'));
    return true;
  }
  const valid = validateShelf(shelf);
  if (!valid) return true;

  const hasOrder = await useOrders().checkExistRecount({
    product_id,
    shelf_id: shelf.shelf_id,
    mode: 'recount',
  });
  if (hasOrder) {
    toRecount(hasOrder);
    return false;
  }
  // выставить границы, иначе выйти из сценария
  if (parent_id) {
    const successSetBorders = await setBorderForOrderWithParent(shelf);
    if (!successSetBorders) {
      toHomePage();
      return false;
    }
  }
  const order_id = await createCheckOrder({
    shelf_id: shelf.shelf_id,
    product_id,
    parent_id,
  });
  if (!order_id) {
    toHomePage();
    return false;
  }
  toRecount(order_id);
  return false;
});
const toHomePage = () => {
  router.push({ name: 'home' });
};

const toRecount = (order_id: BaseOrder['order_id']) => {
  router.push({ name: 'check_product_on_shelf', params: { order_id } });
};

const available = computed(() => {
  return useProducts().availableByProductId(product_id);
});
const product = computed(() => {
  return useProducts().productById(product_id);
});

const validateShelf = (shelf: Shelf): boolean => {
  const valid = ['store', 'markdown', 'kitchen_components', 'kitchen_on_demand', 'office', 'store_robot'].includes(
    shelf.type,
  );
  if (!valid) {
    Alerts.error(
      $gettext('Невозможно создать пересчет на полке с типом %{type}', {
        type: shelfTypes(shelf.type),
      }),
    );
    return false;
  }
  if (shelf.type === 'kitchen_components') {
    const availableOnShelf = available.value?.find(a => a.shelf_id === shelf.shelf_id);
    if (!availableOnShelf) {
      Alerts.error($gettext('Невозможно создать пересчет отсутствующего товара на полке компоненты кухни'));
      return false;
    }
  }
  if (parent_id && !['kitchen_components', 'store'].includes(shelf.type)) {
    Alerts.error($gettext('Запрещено! Положи на ячейку с типом Склад'));
    return false;
  }
  return true;
};
const createCheckOrder = async (option): Promise<false | BaseOrder['order_id']> => {
  const createPayload: OrderCheckRequest = {
    order_id: uuidv1(),
    check: {
      shelf_id: option.shelf_id,
      product_id: option.product_id,
    },
    mode: 'check_product_on_shelf',
    parent: option.parent_id ? [option.parent_id] : undefined,
    update_valids: false,
  };
  const { closeLoader, updateLoader } = showLoader($gettext('Отправляем запрос на создание пересчета'));
  try {
    const response: AxiosResponse<OrderCheckResponse> = await useOrders().createCheck(createPayload);
    const order_id = response.data.order.order_id;
    updateLoader($gettext('Резервируем товар для проведения пересчета'), order_id);
    await useSubscribeOnSuggests(order_id)(s => {
      if (!s) return false;
      if (s.size >= 1) {
        return true;
      }
      return false;
    });
    closeLoader();
    return order_id;
  } catch (e: unknown) {
    closeLoader();
    logger.error(e, { method: 'createCheckOrder' });
    if (isAxiosError(e)) {
      const { response } = e;
      switch (true) {
        case response?.status === 400 && response.data.message === 'Product in kitchen assortment':
          Alerts.error($gettext('Нельзя пересчитывать готовую еду'));
          break;
        case response?.status === 400 && response?.data.code === 'NON_QUANTS_PRODUCT':
          await Modal.show({
            title: $gettext('Нельзя пересчитать непорционный товар'),
            text: $gettext('Переместите данный товар с кухонной полки и выполните пересчет') + '\nNON_QUANTS_PRODUCT',
          });
          break;
        default:
          Alerts.error($gettext('Не удалось создать задание на пересчет'));
          break;
      }
    }
    AudioService.playError();
    return false;
  }
};
const setBorderForOrderWithParent = async (shelf: Shelf): Promise<boolean> => {
  if (!product.value) return false;
  if (!parent_id) return true;
  const { closeLoader } = showLoader();
  const available = await useProducts().fetchAvailable(product_id);
  closeLoader();
  if (!available) {
    Alerts.error($gettext('Произошла ошибка при получении остатков. Попробуйте позже'));
    return false;
  }
  const availableOnShelf = available.find(a => a.shelf_id === shelf.shelf_id);
  const countOnShelf = availableOnShelf?.count || 0;
  try {
    const { closeLoader } = showLoader();
    const { data } = await api.order.acceptance_result_count({
      order_id: parent_id,
      product_id,
      shelf_id: shelf.shelf_id,
    });
    closeLoader();
    let min: number = countOnShelf;
    let max: number | undefined = undefined;
    if (data.result_count > 0) {
      min -= data.result_count;
      min = Math.max(0, min);
    }
    if (data.available_to_recount !== null) {
      max = countOnShelf + data.available_to_recount;
    }
    if (shelf.isKitchenShelf && product.value.quants > 1) {
      min = ~~(min / product.value.quants);
      max = max && ~~(max / product.value.quants);
    }
    useOrders().setBorders(parent_id, [min, max]);
    return true;
  } catch (e: unknown) {
    closeLoader();
    if (isAxiosError(e)) {
      const { response } = e;
      if (response?.status) {
        const { code, message } = response.data;
        Modal.show({
          title: $gettext('Ошибка %{status}', {
            status: String(response?.status),
          }),
          text: $gettext('%{code}: %{message}', {
            code: code,
            message: getErrorMessage(code, message) || message,
          }),
        });
      }
    } else {
      Alerts.error($gettext('Невозможно откорректировать приход'));
    }
    return false;
  }
};
const getErrorMessage = (errorType: string, errorMessage: string = 'Некорректный запрос') => {
  // status 400
  if (errorType === 'ER_BAD_REQUEST') {
    switch (errorMessage) {
      case 'Stowage is completed too long ago':
        return $gettext('Дочерние размещения закрыты слишком давно');
      case 'Acceptance is not completed':
        return $gettext('Приемка не завершена');
      case 'Acceptance child is not completed':
        return $gettext('Дочерний документ приемки не завершен');
      case 'Acceptance has no stowages':
        return $gettext('У приемки нет дочерних раскладок');
      case 'Product not found in acceptance or stowages':
        return $gettext('Товара нет ни в приемке, ни в раскладке');
      case 'Wrong order type':
        return $gettext('Документ не приемка');
      default:
        return errorMessage;
    }
    // status 401
  } else if (errorType === 'ER_AUTH') {
    return $gettext('Требуется авторизоваться');
    // status 403
  } else if (errorType === 'ER_ACCESS') {
    return $gettext('В доступе отказано');
  }
  // other status
  return undefined;
};
</script>
