<template>
  <Layout>
    <div class="flex flex-col justify-end pb-6 h-full" data-test="truck-number step">
      <Title4 class="text-center mx-[30px] mb-6 font-bold"> {{ $gettext('Введите госномер машины') }}</Title4>
    </div>
    <template #footer>
      <CarNumberKeyboard
        :truck_number="truckNumber"
        :name="`truck-number-${order.order_id}`"
        @back="$emit('back')"
        @done="truck_number => $emit('done', truck_number)"
      />
    </template>
  </Layout>
</template>
<script lang="ts">
import CarNumberKeyboard from '@/fsd/widgets/acceptance/CheckTransportConditons/condition-steps/components/CarNumberKeyboard.vue';
import AcceptanceOrder from '@/models/orders/AcceptanceOrder';
import Layout from '@/ui/common/layout.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'TruckNumber',
  components: {
    CarNumberKeyboard,
    Title4,
    Layout,
  },
  props: {
    order: {
      type: Object as PropType<AcceptanceOrder>,
      required: true,
    },
    truckNumber: {
      type: String,
      default: '',
    },
  },
  emits: ['done', 'back'],
});
</script>
