import IconCancelForModal from '@/fsd/shared/icons/IconCancelForModal.vue';
import IconWarnForModal from '@/fsd/shared/icons/IconWarnForModal.vue';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum, InfoItem } from '@/fsd/shared/universalModal';
import { ERR, ERR_STATUS_BAD_TRUE_MARK } from '@/temp/constants/errors';
import { $gettext, gettextWrapper } from '@/temp/plugins/gettext';
import { markRaw } from 'vue';

const TITTLE_DESCRIPTION_ERROR = $gettext('Описание ошибки');
const TITTLE_CODE_ERROR = $gettext('Код ошибки');

const ERROR_DESCRIPTION = gettextWrapper({
  [ERR.ER_MARK_IS_SELLING_YET]: $gettext('Марка товара не вернулась в оборот, её можно будет отсканировать позже'),
  [ERR.ER_RETIRED_TRUE_MARK_WAITING_FOR_SALE_CONFIRMATION]: $gettext(
    'Марка товара не вернулась в оборот, её можно будет отсканировать позже',
  ),
  [ERR.ER_RETIRED_TRUE_MARK_ON_REVIEW]: $gettext('Товар нужно списать, это сделает директор даркстора'),
  [ERR.ER_RETIRED_TRUE_MARK_ON_STORE]: $gettext(
    'Марка товара недействительна — его нужно списать, это сделает директор даркстора',
  ),
  [ERR.ER_TRUE_MARK_HAS_EXPIRED]: $gettext('Товар просрочен, его нужно списать — это сделает директор даркстора'),
  [ERR.ER_SUGGEST_INVALID_TRUE_MARK]: $gettext(
    'Марка товара недействительна — его нужно списать, это сделает директор даркстора',
  ),
  [ERR.ER_RETIRED_TRUE_MARK_OTHER_STORE]: $gettext(
    'Марка товара недействительна — его нужно списать, это сделает директор даркстора',
  ),
  [ERR.ER_RETIRED_TRUE_MARK_OUTSIDE]: $gettext(
    'Марка товара недействительна — его нужно списать, это сделает директор даркстора',
  ),
  [ERR.ER_SUGGEST_TRUE_MARK_DUPLICATED]: $gettext('Сканировать Честный знак для каждого товара нужно отдельно'),
  [ERR.ER_SUGGEST_TRUE_MARK_CONSUMED]: $gettext('Сканировать Честный знак для каждого товара нужно отдельно'),
  [ERR.ER_SUGGEST_TRUE_MARK_IN_ANOTHER_ORDER]: $gettext('Сканировать Честный знак для каждого товара нужно отдельно'),
});

const ERROR_DESCRIPTION_BY_STATUS = gettextWrapper({
  [ERR_STATUS_BAD_TRUE_MARK.NOT_APPLIED]: $gettext('Марка товара не введена в оборот поставщиком'),
  [ERR_STATUS_BAD_TRUE_MARK.NOT_VERIFIED]: $gettext('Марка товара не проверена поставщиком'),
  [ERR_STATUS_BAD_TRUE_MARK.RETIRED]: $gettext('Марка товара выведена из оборота'),
  [ERR_STATUS_BAD_TRUE_MARK.BLOCKED]: $gettext('Марка товара заблокирована'),
  [ERR_STATUS_BAD_TRUE_MARK.NOT_REALIZABLE]: $gettext('Марка товара не введена в оборот поставщиком'),
});

const WARN_ICON_LIST = [
  ERR.ER_INVALID_TRUE_MARK,
  ERR.ER_SUGGEST_TRUE_MARK_REQUIRED,
  ERR.ER_EXTERNAL_SERVICE,
  ERR.ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN,
  ERR.ER_SUGGEST_TRUE_MARK_DUPLICATED,
  ERR.ER_SUGGEST_WRONG_TRUE_MARK,
  ERR.ER_SUGGEST_TRUE_MARK_NOT_IN_CURRENT_ORDER,
];

interface TrueMarkModal {
  title: string;
  text?: string;
  code: ERR;
  status?: ERR_STATUS_BAD_TRUE_MARK;
}

const trueMarkModal = async ({ title, text, code, status }: TrueMarkModal) => {
  const infoBlock: InfoItem[] = [];

  if (ERROR_DESCRIPTION[code]) {
    infoBlock.push({
      subtitle: TITTLE_DESCRIPTION_ERROR,
      title: ERROR_DESCRIPTION[code],
    });
  }

  if (code === ERR.ER_BAD_TRUE_MARK && status && ERROR_DESCRIPTION_BY_STATUS[status]) {
    infoBlock.push({
      subtitle: TITTLE_DESCRIPTION_ERROR,
      title: ERROR_DESCRIPTION_BY_STATUS[status],
    });
  }

  infoBlock.push({
    subtitle: TITTLE_CODE_ERROR,
    title: code,
  });

  await Modal.show({
    title,
    text,
    infoBlock,
    component: WARN_ICON_LIST.includes(code) ? markRaw(IconWarnForModal) : markRaw(IconCancelForModal),
    btnPosition: ButtonPositionsEnum.one_button,
    confirmBtnTitle: $gettext('Хорошо'),
  });
};

export const checkNeedTrueMarkModal = (code?: string): boolean => {
  return !!code && !!ERR[code];
};

export const errorTrueMarkModal = async (code: ERR, status?: ERR_STATUS_BAD_TRUE_MARK) => {
  let title = $gettext('Отсканируйте Честный знак ещё раз');
  let text = '';

  switch (code) {
    case ERR.ER_INVALID_TRUE_MARK:
    case ERR.ER_SUGGEST_TRUE_MARK_REQUIRED:
      title = $gettext('Отсканируйте Честный знак');
      text = $gettext('Похоже, вы отсканировали штрихкод вместо Честного знака. Вам нужен небольшой квадратный код');
      break;
    case ERR.ER_TRUE_API_BROKEN:
    case ERR.ER_MARK_IS_SELLING_YET:
    case ERR.ER_RETIRED_TRUE_MARK_WAITING_FOR_SALE_CONFIRMATION:
    case ERR.ER_NO_PRODUCT_FOR_MARK_IN_COMPANY:
      title = $gettext('Отсканируйте другой товар, а этот пока верните на полку');
      break;
    case ERR.ER_EXTERNAL_SERVICE:
    case ERR.ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN:
      title = $gettext('Отсканируйте Честный знак ещё раз');
      text = $gettext('Не удалось загрузить информацию, нужно попробовать ещё раз');
      break;
    case ERR.ER_RETIRED_TRUE_MARK_ON_REVIEW:
    case ERR.ER_RETIRED_TRUE_MARK_ON_STORE:
    case ERR.ER_TRUE_MARK_HAS_EXPIRED:
    case ERR.ER_SUGGEST_INVALID_TRUE_MARK:
    case ERR.ER_RETIRED_TRUE_MARK_OTHER_STORE:
    case ERR.ER_RETIRED_TRUE_MARK_OUTSIDE:
    case ERR.ER_BAD_TRUE_MARK:
      title = $gettext('Отнесите этот товар на полку «Разбор»');
      text = $gettext('Затем возьмите другой товар и отсканируйте его Честный знак');
      break;
    case ERR.ER_SUGGEST_TRUE_MARK_DUPLICATED:
      title = $gettext('Вы уже отсканировали этот товар, положите его в корзину и возьмите следующий');
      break;
    case ERR.ER_SUGGEST_TRUE_MARK_CONSUMED:
    case ERR.ER_SUGGEST_TRUE_MARK_IN_ANOTHER_ORDER:
      title = $gettext('Этот товар собрали в другом заказе — верните его на полку и возьмите другой');
      break;
    case ERR.ER_SUGGEST_WRONG_PRODUCT_TRUE_MARK:
      title = $gettext('Вы отсканировали не тот товар, проверьте состав заказа');
      break;
    case ERR.ER_SUGGEST_WRONG_TRUE_MARK:
    case ERR.ER_SUGGEST_TRUE_MARK_NOT_IN_CURRENT_ORDER:
      title = $gettext('Вы отсканировали не тот Честный знак ');
      text = $gettext('Нужно отсканировать ту же марку товара, что и при сборке заказа');
  }

  await trueMarkModal({ title, text, code, status });
};
