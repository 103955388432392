<template>
  <div v-if="needShow" data-test="suggest card count row">
    <div v-if="variant === 'multi-row'" class="flex flex-col border-t">
      <Caption1 color="primary" class="font-bold">{{ $gettext('Количество') }}</Caption1>
      <Row :label="$gettext('Фактическое')" :value="resultCountValue" data-test="suggest card result-count row" />
      <Row :label="$gettext('Учётное')" :value="countValue" data-test="suggest card count row" />
    </div>
    <Row v-else :label="label">
      <div class="flex flex-col gap-1">
        <Caption1 color="primary" data-test="suggest row value">{{ resultCountValue }}</Caption1>
        <template v-if="needShowResultCount">
          <Caption1 color="semantic-text-minor">{{ $gettext('из') }}</Caption1>
          <Caption1 color="primary" data-test="suggest row value">{{ countValue }}</Caption1>
        </template>
      </div>
    </Row>
  </div>
</template>

<script lang="ts">
// меняем концепцию использования полей для отображения кол-ва
// теперь возможно 2 варианта:
// - отображаем и то и другое 2 разными полями (multi-row)
// - отображаем одно поля в котором показываем кол-во из саджеста и итоговое кол-во. разделяя их словом "из"(combined-row)

import { getValueForView } from '@/fsd/entities/product';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import { ControlModeEnum, isControlOrder } from '@/models/orders/ControlOrder';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { getCountTitleDone } from '@/temp/constants/translations/countTitles';
import { DocTypes } from '@/temp/constants/translations/types';
import Row from '@/ui/common/suggest-card/row.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  components: { Caption1, Row },
  props: {
    docType: {
      type: String as PropType<DocTypes>,
      required: true,
    },
    order: {
      type: Object as PropType<BaseOrder>,
      default: undefined,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: undefined,
    },
  },
  setup() {
    const shelvesStore = useShelves();
    const productsStore = useProducts();
    const userStore = useUser();

    return { shelvesStore, productsStore, userStore };
  },
  computed: {
    isRTL(): boolean {
      return this.userStore.isRTL;
    },
    variant(): 'multi-row' | 'combined-row' {
      if (['check'].includes(this.docType)) {
        return 'multi-row';
      }
      return 'combined-row';
    },
    shelf(): Shelf {
      return this.shelvesStore.shelfById(this.suggest?.shelf_id)!;
    },
    product(): Product {
      return this.productsStore.productById(this.suggest?.product_id)!;
    },
    needShow(): boolean {
      // Если это продукт - то показываем
      // для посылок не нужно  для марок тоже не нужно
      if (
        !this.suggest ||
        !this.order ||
        !this.product ||
        this.suggest?.vars?.mode === 'item' ||
        this.suggest?.conditions?.need_true_mark
      )
        return false;
      // не нужно для данных типов док-ов
      if (['onlyTitle', 'create-acceptance-item', 'create-writeoff-by-order-item'].includes(this.docType)) return false;
      if (['pack'].includes(this.docType)) {
        return this.suggest.status === 'done';
      }
      if (this.order?.type === 'acceptance') {
        return true;
      }
      return !(!this.suggest || this.suggest.count === null || this.suggest.count === undefined);
    },
    label(): string {
      //  ксг в режиме уценки нужен отдельный заголовок для количества на списание. (мы переносим на полку уценки, а не списания)
      if (
        this.order &&
        isControlOrder(this.order) &&
        this.suggest?.vars.stage === 'write_off' &&
        [ControlModeEnum.store2markdown, ControlModeEnum.store2review, ControlModeEnum.eatToday2markdown].includes(
          this.order.vars.mode,
        )
      )
        return this.$gettext('Размещено');

      return getCountTitleDone({ docType: this.docType, suggest: this.suggest });
    },
    countValue(): string {
      let count = this.suggest?.count;
      let weight = this.suggest?.weight;
      if (this.suggest?.status === 'request') {
        if (['check'].includes(this.docType)) {
          let productOnShelf;
          if (['trash', 'kitchen_trash'].includes(this.shelf?.type)) {
            const available = this.shelvesStore.availableById(this.suggest?.shelf_id);
            productOnShelf = available?.find(item => item.product_id === this.suggest?.product_id)!;
          } else {
            const available = this.productsStore.availableByProductId(this.suggest.product_id);
            productOnShelf = available?.find(item => item.shelf_id === this.suggest?.shelf_id)!;
          }
          count = productOnShelf?.count || 0;
        }
      }

      // в контрольном пересчете скрываем учетное кол-во, пока пересчет не будет закончен
      if (this.order?.type === 'check_final' && this.suggest?.status === 'request') {
        return '-';
      }

      return getValueForView({ product: this.product, shelf: this.shelf, count, weight, order: this.order });
    },
    resultCountValue(): string {
      let count = this.suggest?.result_count || 0;
      let weight = this.suggest?.result_weight!;
      if (this.suggest?.status === 'request') {
        if (['check', 'check_final', 'inventory_check_product_on_shelf'].includes(this.order!.type)) {
          return '-';
        }
      }
      return getValueForView({ product: this.product, shelf: this.shelf, count, weight, order: this.order });
    },
    needShowResultCount(): boolean {
      return !['check_more', 'inventory_check_more'].includes(this.order!.type);
    },
  },
});
</script>
