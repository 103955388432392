import { api } from '@/fsd/data/api/api.service';
import OrderEvent from '@/models/events/OrderEvent';
import BaseOrder, { OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import Suggest from '@/models/Suggest';
import { useUser } from '@/store/modules/user';
import { logger } from '@/temp/plugins/logs';
import { defineStore } from 'pinia';

export const useSuggests = defineStore('suggests', {
  state: () => ({
    allSuggests: new Map() as Map<BaseOrder['order_id'], Map<Suggest['suggest_id'], Suggest>>,
    promise: new Map() as Map<BaseOrder['order_id'], Promise<Suggest[] | false>>,
  }),
  getters: {
    suggestsByOrderId(state) {
      return (order_id: BaseOrder['order_id']): Map<Suggest['suggest_id'], Suggest> => {
        return state.allSuggests.get(order_id) as Map<Suggest['suggest_id'], Suggest>;
      };
    },
    getSuggest() {
      return (order_id: BaseOrder['order_id'], suggest_id: Suggest['suggest_id']): Suggest | undefined => {
        const orderSuggests = this.suggestsByOrderId(order_id);
        if (!orderSuggests) return undefined;
        return orderSuggests.get(suggest_id) as Suggest;
      };
    },
    getRequestSuggestsPromise() {
      return (order_id: BaseOrder['order_id']): Promise<Suggest[] | false> | undefined => {
        return this.promise.get(order_id);
      };
    },
  },
  actions: {
    addSuggests(suggests: Suggest[]) {
      suggests.forEach(s => this.addSuggest(s));
    },
    addSuggest(newSuggest: Suggest) {
      if (!this.allSuggests.has(newSuggest.order_id)) {
        this.allSuggests.set(newSuggest.order_id, new Map());
      }
      const orderSuggests = this.allSuggests.get(newSuggest.order_id)!;
      const oldSuggest = orderSuggests.get(newSuggest.suggest_id);
      // если саджеста нет в хранилище или ревизия у загруженного саджеста больше, то кладем новый саджест в хранилище
      if (!oldSuggest || newSuggest.revision >= oldSuggest.revision) {
        orderSuggests.set(newSuggest.suggest_id, newSuggest);
      }
    },
    removeSuggest(order_id: BaseOrder['order_id'], suggest_id: Suggest['suggest_id']) {
      const orderSuggests = this.suggestsByOrderId(order_id);
      if (!orderSuggests) return;
      orderSuggests.delete(suggest_id);
    },
    removeOrderSuggests(order_id: BaseOrder['order_id']) {
      this.allSuggests.delete(order_id);
    },

    checkNeedLoadSuggests(data: BaseOrder | OrderEvent) {
      // уже грузили
      if (this.allSuggests.has(data.order_id)) return false;
      // не подходят статусы
      if (data.status !== OrderWorkStatusEnum.processing || data.estatus !== 'waiting') return false;
      // если пользователя нет в юзерс, то ордер не наш - не грузим его.
      if (!data.users.includes(useUser().userId)) return false;
      return true;
    },

    async loadSuggests(data: OrderEvent | BaseOrder, force?: boolean): Promise<Suggest[] | false> {
      // если уже грузим, то возвращаем промис для ожидания
      if (this.promise.has(data.order_id)) return this.promise.get(data.order_id)!;
      // если пришел флаг force, то мы точно хотим загрузить саджесты.
      if (!this.checkNeedLoadSuggests(data) && !force) {
        return false;
      }
      let resolve: (value: Suggest[] | false) => void = () => {};
      // создаем промис, чтобы из loadOrders дождаться завершения загрузки саджестов и ресурсов
      this.promise.set(
        data.order_id,
        new Promise<Suggest[] | false>(res => {
          resolve = res;
        }),
      );
      try {
        // сразу же выставляем ордеру флаг, как уже загруженному.
        const response = await api.order.suggests({ order_id: data.order_id });

        const relatedDataPromises: Promise<any>[] = [];
        // загружаем продукты, посылки и полки для саджестов
        response.data.suggests.forEach(s => {
          relatedDataPromises.push(s.loadProduct());
          relatedDataPromises.push(s.loadShelf());
        });
        await Promise.allSettled(relatedDataPromises);
        this.addSuggests(response.data.suggests);
        resolve(response.data.suggests);
        return response.data.suggests;
      } catch (e) {
        logger.error(e, { method: 'loadSuggests' });
        resolve(false);
        return false;
      } finally {
        this.promise.delete(data.order_id);
      }
    },
    async loadSuggest(order_id: BaseOrder['order_id'], suggest_id: Suggest['suggest_id']) {
      // Логика следующая: если все саджесты еще не были загружены, то нет смысла грузить что то по одному, это все равно будет перезаписано после общего запроса.
      if (!this.allSuggests.has(order_id)) return;
      try {
        const { data } = await api.order.get_suggest({ order_id: order_id, suggest_id: [suggest_id] });
        const suggest = data.suggest[0];
        const relatedDataPromises: Promise<any>[] = [];
        // загружаем продукты, посылки и полки для саджестов
        relatedDataPromises.push(suggest.loadProduct());
        relatedDataPromises.push(suggest.loadShelf());
        await Promise.allSettled(relatedDataPromises);
        this.addSuggests(data.suggest);
        return suggest;
      } catch (e) {
        console.error(e);
        // бывают кейсы, когда нельзя удалять документ. Пока не знаю, что делать в этом случае.
        // Пример: бек поменял саджест, затем его удалил, в процессе отправил нам ивент, мы пытаемся его загрузить и в результате ловим 403(
        // if (e && axios.isAxiosError(e) && e.response) {
        //   const response = (e as AxiosError<BaseError>)['response']!;
        //   if (response.status === 403) {
        //     //   означает,что документ не наш. нужно удалить из локального хранилища
        //     useOrders().removeOrder(order_id);
        //     return;
        //   }
        // }
      }
    },
  },
});
