<template>
  <UseDraggable
    class="bg-gray-300 rounded-full border absolute z-[51000]"
    :initial-value="initialValue"
    :style="style"
    @move="onMove"
  >
    <button class="flex touch-none items-center justify-center size-16">
      <slot />
    </button>
  </UseDraggable>
</template>

<script setup lang="ts">
import { UseDraggable } from '@vueuse/components';
import { ref } from 'vue';

interface Props {
  storageKey?: string;
}

interface Position {
  x: number;
  y: number;
}

const props = defineProps<Props>();

const BTN_SIZE_DEFAULT = 64;
const POSITION_DEFAULT = { x: 0, y: 0 };

const style = ref({});
const initialValue = ref({ ...POSITION_DEFAULT });

const calcPosition = ({ x, y }: Position) => {
  // в браузере может поменяться размер, поэтому берем его здесь
  const { innerHeight, innerWidth } = window;

  const minLeft = 0;
  const maxLeft = innerWidth - BTN_SIZE_DEFAULT;
  const minTop = 0;
  const maxTop = innerHeight - BTN_SIZE_DEFAULT;

  let left = x;
  let top = y;

  if (left < minLeft) {
    left = minLeft;
  } else if (left > maxLeft) {
    left = maxLeft;
  }

  if (top < minTop) {
    top = minTop;
  } else if (top > maxTop) {
    top = maxTop;
  }

  return { x: left, y: top };
};

const setStyle = ({ x, y }: Position) => {
  style.value = {
    left: `${x}px`,
    top: `${y}px`,
  };
};

const onMove = (position: Position) => {
  const calculatedPosition = calcPosition(position);
  setStyle(calculatedPosition);
  if (props.storageKey) {
    localStorage.setItem(props.storageKey, JSON.stringify(calculatedPosition));
  }
};

// пробуем установить значение из localStorage
if (props.storageKey) {
  try {
    const storageValue = localStorage.getItem(props.storageKey);
    const storagePosition = storageValue && JSON.parse(storageValue);
    const calculatedPosition = calcPosition(storagePosition);
    setStyle(calculatedPosition);
    initialValue.value = calculatedPosition;
  } catch {
    initialValue.value = { ...POSITION_DEFAULT };
  }
}
</script>
