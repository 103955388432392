import Suggest, { SuggestStatusEnum } from '@/models/Suggest';

interface UpdateOptions extends Partial<Suggest> {
  suggests: Suggest[];
}

export default class TrueMarkSuggestWrapper extends Suggest {
  public suggests: Suggest[] = [];
  public virtual: true = true;
  public count: number = 0;
  public result_count: number = 0;
  public status: SuggestStatusEnum = SuggestStatusEnum.request;

  constructor(data: UpdateOptions) {
    super(data);
    this.addSuggest = data.suggests[0];
  }

  set addSuggest(suggest: Suggest) {
    this.suggests.push(suggest);
    this.count = this.suggests.reduce((acc, s) => acc + (s?.count || 0), 0);
    this.result_count = this.suggests.reduce((acc, s) => acc + (s?.result_count || 0), 0);
    this.status = this.result_count === this.count ? SuggestStatusEnum.done : SuggestStatusEnum.request;
  }

  get isDone() {
    return this.suggests.every(suggest => suggest.isDone);
  }

  get isBlocked() {
    return this.suggests.every(suggest => suggest.isBlocked);
  }

  get isCanceled() {
    return this.suggests.every(suggest => suggest.isCanceled);
  }

  get isCompleted() {
    return this.isDone || this.isCanceled;
  }

  static isTrueMarkSuggestWrapper(suggest: Suggest | TrueMarkSuggestWrapper): suggest is TrueMarkSuggestWrapper {
    return suggest.virtual && suggest.conditions.need_true_mark;
  }

  get suggestsIds(): Suggest['suggest_id'][] {
    return this.suggests.map(suggest => suggest.suggest_id);
  }

  get isLastRequestSuggest(): boolean {
    const requestSuggests = this.suggests.filter(suggest => suggest.status === SuggestStatusEnum.request);
    return requestSuggests.length === 1;
  }
}
