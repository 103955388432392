<template>
  <div class="flex items-center pl-[3px] w-[32px]">
    <p class="w-[13px] text-center">{{ errorCount }}</p>
    <div class="w-[16px] min-w-4 h-4 flex justify-center items-center">
      <ApiIcon :class="apiIndicatorColorClass" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useIndicators } from '@/store/indicators';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import ApiIcon from './icons/ApiIcon.vue';

const { errorCount, hasRequest } = storeToRefs(useIndicators());

const apiIndicatorColorClass = computed(() => {
  if (hasRequest.value) {
    return 'text-blue-normal';
  }
  return 'text-semantic-text-minor';
});
</script>
