import { api } from '@/fsd/data/api/api.service';
import { checkNeedTrueMarkModal, errorTrueMarkModal } from '@/fsd/entities/modals/errorTrueMarkModal';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { CheckTrueMarkRequest } from '@/services/requests';
import { isAxiosError } from 'axios';

export const TrueMark = {
  // проверка марки ЧЗ
  async check(request: CheckTrueMarkRequest) {
    // Пока что от нас требуется только сообщить о честном знаке, ответ нас не интересует, но когда-нибудь там будет что-то нужное
    try {
      return await api.check_true_mark(request);
    } catch (err: unknown) {
      if (!isAxiosError(err)) return;
      const response = err.response;
      if (!response) return;
      if (checkNeedTrueMarkModal(response.data.code)) {
        errorTrueMarkModal(response.data.code, response.data.true_mark_status);
      } else {
        Modal.show({
          title: response.data.code,
          text: response.data.message,
        });
      }
      // TODO а нужна ли эта строчка?
      throw err;
    }
  },
};
