<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :text="controlBarText"
        :caption="controlBarCaption"
        :menu-config="menuConfig"
        @close-click="onCloseClick"
      />
    </template>

    <template #default>
      <template v-if="activeStep === 'shelf'">
        <Hint class="my-2 mx-4">{{ $gettext('Отсканируйте полку') }}</Hint>
        <ListCardItemContainer>
          <ListCardItem
            v-for="shelfId in allShelvesIds"
            :key="shelfId"
            :is-done="isShelfCompleted(shelfId)"
            :is-in-progress="isShelfInProgress(shelfId)"
            @long-press="setActiveShelf(shelfId)"
          >
            {{ shelvesStore.shelfById(shelfId)!.title }}
          </ListCardItem>
        </ListCardItemContainer>
      </template>
      <template v-if="activeStep === 'products'">
        <div v-if="!activeShelfHasDoneSuggests">
          <Title4
            class="flex text-center justify-center font-medium mt-5 mx-4 mb-[88px]"
            data-test="check_more product-step empty-title"
          >
            {{ productStepHintText }}
          </Title4>
          <img class="w-full py-0 px-4" src="@/assets/img/scan.svg" alt="" />
        </div>
        <div v-else>
          <Hint class="my-2 mx-4">{{ productStepHintText }}</Hint>
          <SuggestCard
            v-for="suggest in activeShelfDoneSuggests"
            :key="suggest.suggest_id"
            :doc-type="OrderTypeEnum.check_more"
            :order="order"
            :suggest="suggest"
          />
        </div>
      </template>
      <SuggestDetails
        v-if="activeProduct && activeShelfSuggest && suggestDetails.visible.value"
        :product-id="activeProduct.product_id"
        :order-id="order_id"
        :suggest-id="activeShelfSuggest.suggest_id"
        @cancel="closeSuggestDetails"
        @finish="finishSuggest"
      />
      <ShareOrder v-if="order && shareOrder.visible.value" :order="order" @close="shareOrder.hide" />
    </template>

    <template #footer>
      <LayoutFooter>
        <UiButton
          v-if="activeStep === 'products'"
          :is-disabled="!isShelfCompletable(activeShelf && activeShelf.shelf_id)"
          data-test="check_more product-step finish-btn"
          @click="finishShelf"
        >
          {{ productStepFinishBtnText }}
        </UiButton>
        <UiButton
          v-if="activeStep === 'shelf'"
          data-test="check_more footer finish-btn"
          :is-disabled="!allSuggestsDone || order?.estatus === OrderEstatusEnum.waiting_signal"
          @click="finishOrder"
        >
          {{ $gettext('Завершить') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import retryCheckMoreModal from '@/fsd/entities/modals/retryCheckMoreModal';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import {
  getDoneSuggests,
  getRequestSuggests,
  getSuggestsByStatusAndType,
} from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useCheckMore } from '@/fsd/entities/suggest/tools/useCheckMore';
import { useDetachFromOrder } from '@/fsd/features/order/utils/useDetachFromOrder';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { Modal } from '@/fsd/shared/tools/modalNotification';
import { ButtonPositionsEnum } from '@/fsd/shared/universalModal';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest, { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import { OrderEstatusEnum, OrderTypeEnum } from '@/models/orders/BaseOrder';
import CheckMoreOrder from '@/models/orders/CheckMoreOrder';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { $gettext, $ngettext } from '@/temp/plugins/gettext';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import ListCardItemContainer from '@/ui/common/list-card-item-container.vue';
import ListCardItem from '@/ui/common/list-card-item/list-card-item.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { MenuItemConfig } from '@/ui/common/menu/types';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { erSuggestAcceptedCountModal, erSuggestNonQuantsProduct } from '@/utils/modals';
import { isAxiosError } from 'axios';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

interface CheckMoreProps {
  order_id: string;
}

type Step = 'shelf' | 'products';

const { order_id } = defineProps<CheckMoreProps>();

const activeProduct = ref<Product>();
const activeShelf = ref<Shelf>();
const activeStep = ref<Step>('shelf');

const { showLoader } = useLoader();
const shelvesStore = useShelves();
const productsStore = useProducts();
const ordersStore = useOrders();
const userStore = useUser();
const shareOrder = useComponent();
const suggestDetails = useComponent();
const router = useRouter();
const { detachBtn } = useDetachFromOrder(order_id);

useHandleOrderStatus(order_id);

const order = computed<CheckMoreOrder | undefined>(() => {
  return ordersStore.orderById(order_id) as CheckMoreOrder;
});

const suggests = computed<Suggest[]>(() => {
  if (!order.value?.suggests) return [];
  return order.value.suggests;
});

const requestSuggests = computed<Suggest[]>(() => {
  return getRequestSuggests(suggests.value);
});

const allSuggestsDone = computed<boolean>(() => {
  return requestSuggests.value.length === 0;
});

const activeShelfDoneSuggests = computed<Suggest[]>(() => {
  if (!activeShelf.value) return [];
  return suggests.value.filter(
    suggest =>
      suggest.status === SuggestStatusEnum.done &&
      suggest.shelf_id === activeShelf.value?.shelf_id &&
      suggest.product_id,
  );
});

const allShelvesIds = computed<string[]>(() => {
  return [...new Set(suggests.value.map(suggest => suggest.shelf_id))];
});

const allShelvesSuggests = computed<Suggest[][]>(() => {
  return allShelvesIds.value.map(shelf_id => suggests.value.filter(suggest => suggest.shelf_id === shelf_id));
});

const remainingShelvesCount = computed<number>(() => {
  // получаем кол-во полок с саджестами в статусе request
  return allShelvesSuggests.value.reduce((acc, shelfSuggests) => {
    return acc + (getRequestSuggests(shelfSuggests).length > 0 ? 1 : 0);
  }, 0);
});

const controlBarText = computed<string>(() => {
  if (activeShelf.value) {
    return $gettext('Инвентаризация');
  }
  if (order.value) {
    if (remainingShelvesCount.value > 0) {
      return $ngettext(
        'Осталось %{request} из %{all} полка',
        'Осталось %{request} из %{all} полок',
        allShelvesIds.value.length,
        {
          request: String(remainingShelvesCount.value),
          all: String(allShelvesIds.value.length),
        },
      );
    }
    return $ngettext('%{n} полка', '%{n} полок', allShelvesIds.value.length, {
      n: String(allShelvesIds.value.length),
    });
  }
  return '';
});

const controlBarCaption = computed<string>(() => {
  if (activeShelf.value) {
    return $gettext('Полка %{shelf}', {
      shelf: activeShelf.value.title,
    });
  }
  return $gettext('Инвентаризация');
});

const productStepFinishBtnText = computed<string>(() => {
  if (activeShelfHasDoneSuggests.value) {
    return $gettext('Завершить');
  }
  return $gettext('Пустая полка');
});

const activeShelfSuggest = computed<Suggest | undefined>(() => {
  return suggests.value.find(
    suggest => suggest.status === SuggestStatusEnum.request && suggest.shelf_id === activeShelf.value?.shelf_id,
  );
});

const getShelfSuggests = (shelf_id: string): Suggest[] => {
  return suggests.value.filter(suggest => suggest.shelf_id === shelf_id);
};

const isShelfCompleted = (shelf_id: string): boolean => {
  const suggests = getShelfSuggests(shelf_id);
  return getSuggestsByStatusAndType(suggests, SuggestStatusEnum.request, SuggestTypeEnum.check_more).length === 0;
};

const isShelfInProgress = (shelf_id: string): boolean => {
  const suggests = getShelfSuggests(shelf_id);
  const hasDone = getDoneSuggests(suggests).length > 0;
  return hasDone && !isShelfCompleted(shelf_id);
};

const isShelfCompletable = (shelf_id: string | undefined): boolean => {
  if (!shelf_id) return false;
  const suggests = getShelfSuggests(shelf_id);
  return getRequestSuggests(suggests).length > 0;
};

const activeShelfHasDoneSuggests = computed<boolean>(() => {
  return activeShelfDoneSuggests.value.length > 0;
});

const setActiveShelf = (shelf_id: string) => {
  const shelf = shelvesStore.shelfById(shelf_id);
  if (!shelf) {
    Alerts.error($gettext('Полка не найдена'));
    return;
  }
  activeShelf.value = shelf;
  activeStep.value = 'products';
};

const { needBarcodeRequest } = useRequestBarcode(async barcode => {
  if (activeStep.value === 'shelf') {
    try {
      const shelf = await shelvesStore.getShelfByBarcode(barcode);
      if (shelf) {
        if (getShelfSuggests(shelf.shelf_id).length > 0) {
          setActiveShelf(shelf.shelf_id);
        } else {
          Alerts.error($gettext('Отсканирована неверная полка'));
        }
      } else {
        Alerts.error($gettext('Полка не найдена'));
      }
      return true;
    } catch (error) {
      Alerts.error($gettext('Не найден штрихкод %{barcode}', { barcode }));
      return true;
    }
  } else {
    const { closeLoader } = showLoader($gettext('Запрашиваем продукт по штрихкоду'), order_id);
    try {
      const product = await productsStore.getProductByBarcode(barcode);
      activeProduct.value = product;
      suggestDetails.show();
      return false;
    } catch (error) {
      Alerts.error($gettext('Не найден штрихкод %{barcode}', { barcode }));
      return true;
    } finally {
      closeLoader();
    }
  }
});

const showShareOrder = async () => {
  needBarcodeRequest.value = false;
  await shareOrder.asyncShow();
  needBarcodeRequest.value = true;
};

const menuConfig = computed<MenuItemConfig[]>(() => {
  const menuConfig: MenuItemConfig[] = [];

  if (order.value?.type === OrderTypeEnum.inventory_check_more) {
    menuConfig.push(detachBtn.value);
  }

  const shareOrderBtn: MenuItemConfig = {
    buttonText: $gettext('Разделить задание'),
    onClick: () => showShareOrder(),
    dataTest: 'share order btn',
    condition: () =>
      userStore.experimentByName(experiments.exp_tsd_companion) &&
      order.value?.type === OrderTypeEnum.inventory_check_more,
  };
  menuConfig.push(shareOrderBtn);

  return menuConfig;
});

const activeShelfIsCompleted = computed<boolean>(() => {
  return activeShelfSuggest.value?.status === SuggestStatusEnum.error;
});

const productStepHintText = computed<string>(() => {
  if (activeShelfIsCompleted.value) {
    return $gettext('Подсчет полки завершен');
  }
  return $gettext('Отсканируйте товары, которые лежат на полке');
});

const toHomePage = () => {
  router.push({ name: 'home' });
};

const finishOrder = async () => {
  if (order.value?.vars.third_party_assistance) {
    await Modal.show({
      title: $gettext('Требуется ручное завершение подсчета в диспетчерской'),
    });
    return;
  }
  const confirmed = await Modal.show({
    title: $gettext('Вы уверены?'),
    btnPosition: ButtonPositionsEnum.horizontal,
  });
  if (!confirmed) return;
  needBarcodeRequest.value = false;
  const result = await useEndOrder(order_id);
  if (result) {
    toHomePage();
  } else {
    needBarcodeRequest.value = true;
  }
};

const resetState = () => {
  activeProduct.value = undefined;
  activeShelf.value = undefined;
  activeStep.value = 'shelf';
};

const finishShelf = async () => {
  const confirmed = await Modal.show({
    title: $gettext('Вы уверены?'),
    btnPosition: ButtonPositionsEnum.horizontal,
  });
  if (!confirmed) return;

  const result = await useCheckMore(
    order_id,
    { status: 'error', suggest_id: activeShelfSuggest.value!.suggest_id },
    {
      onRequestError: async (e, retry) => {
        if (isAxiosError(e)) {
          if ((e.response?.data as any).code === 'ER_SUGGEST_NON_QUANTS_PRODUCT') {
            await erSuggestNonQuantsProduct();
            return;
          }
        }

        const confirmed = await retryCheckMoreModal(e);
        if (!confirmed) return false;
        return retry();
      },
    },
  );
  if (!result) return;
  resetState();
  suggestDetails.hide();
  needBarcodeRequest.value = true;
};

const onCloseClick = () => {
  if (activeStep.value === 'products') {
    resetState();
    needBarcodeRequest.value = true;
  } else {
    resetState();
    toHomePage();
  }
};

const closeSuggestDetails = () => {
  activeProduct.value = undefined;
  suggestDetails.hide();
  needBarcodeRequest.value = true;
};

const finishSuggest = async ({ count = 0, date }: Pick<Model, 'count' | 'date'>) => {
  await useCheckMore(
    order_id,
    {
      suggest_id: activeShelfSuggest.value!.suggest_id,
      product_id: activeProduct.value!.product_id,
      count,
      valid: date,
    },
    {
      onRequestError: async (e, retry) => {
        if (isAxiosError(e)) {
          if ((e.response?.data as any).code === 'ER_SUGGEST_ACCEPTED_COUNT') {
            await erSuggestAcceptedCountModal();
            return;
          }
          if ((e.response?.data as any).code === 'ER_SUGGEST_NON_QUANTS_PRODUCT') {
            await erSuggestNonQuantsProduct();
            return;
          }
        }

        const confirmed = await retryCheckMoreModal(e);
        if (!confirmed) return false;
        return retry();
      },
    },
  );
  closeSuggestDetails();
};

watch(
  order,
  newOrder => {
    if (!newOrder) {
      Alerts.success($gettext('Инвентаризация успешно выполнена'));
      toHomePage();
      return;
    }
  },
  { immediate: true },
);

watch(
  () => order.value?.estatus,
  newEstatus => {
    if (newEstatus === OrderEstatusEnum.waiting_signal) {
      Modal.show({
        title: $gettext('Подсчет стеллажа завершен'),
        text: $gettext('Требуется ручное завершение подсчета в диспетчерской'),
      });
      toHomePage();
    }
  },
  { immediate: true },
);
</script>
