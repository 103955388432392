import Suggest from '@/models/Suggest';
import { BaseModel } from 'sjs-base-model';

export default class SuggestEvent extends BaseModel {
  public type: 'suggest' = 'suggest';

  public suggest_id: Suggest['suggest_id'] = '';
  public status: Suggest['status'] | undefined = undefined;

  public order_id: Suggest['order_id'] | undefined = undefined;
  public suggest_type: Suggest['type'] | undefined = undefined;
  public conditions: Suggest['conditions'] | undefined = undefined;
  public product_id: Suggest['product_id'] | undefined = undefined;
  public shelf_id: Suggest['shelf_id'] | undefined = undefined;

  public count: Suggest['count'] | undefined = undefined;
  public result_count: Suggest['result_count'] | undefined = undefined;
  public valid: Suggest['valid'] | undefined = undefined;
  public result_valid: Suggest['result_valid'] | undefined = undefined;
  public revision: Suggest['revision'] = 0;
  public vars: {
    price: string | undefined;
  } = { price: undefined };

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }

  static isSuggestEvent(event: { type: string }): event is SuggestEvent {
    return event.type === 'suggest';
  }
}
