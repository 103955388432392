import { PlatformService } from '@/fsd/data/services/platform.service';
import WebViewUpdater from '@/fsd/features/tools/useWebViewUpdate/WebViewUpdater.vue';
import { callAction } from '@/fsd/shared/ui/action';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { logger } from '@/temp/plugins/logs';
import { Device } from '@capacitor/device';
import { scanner, WebViewNameEnum } from '@lavka/capacitor-scanner-plagin';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';

const MINIMAL_WEB_VIEW_VERSION = 94;
const checkNeedUpdate = async () => {
  const { webViewName } = await scanner.info();
  // Если выключен эксп либо мы не понимаем, как обновляться, то выходим из сценария. 
  if (
    !PlatformService.isCapacitor() ||
    !useUser().experimentByName(experiments.exp_tsd_update_webview) ||
    [WebViewNameEnum.GH, WebViewNameEnum.ASWV].includes(webViewName)
  )
    return false;
  try {
    const { webViewVersion } = await Device.getInfo();
    const [major] = webViewVersion.split('.');
    return +major < MINIMAL_WEB_VIEW_VERSION;
  } catch (e) {
    logger.error(e);
    return false;
  }
};

export const showUpdateWindow = () => {
  callAction(WebViewUpdater);
};

export const useWebViewUpdate = () => {
  const { isAuthenticated } = storeToRefs(useUser());

  watch(isAuthenticated, val => {
    if (!val) return;
    checkNeedUpdate().then(r => {
      if (r) showUpdateWindow();
    });
  });
};
