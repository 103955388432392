<template>
  <Row v-if="needShow" :label="label" :value="value" data-test="suggest-details shelf row" />
</template>

<script lang="ts">
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import Suggest, { SuggestTypeEnum } from '@/models/Suggest';
import { useShelves } from '@/store/modules/shelves';
import { getShelfTitle } from '@/temp/constants/translations/shelfTitles';
import Row from '@/ui/common/suggest-details/row.vue';
import { Model } from '@/ui/common/suggest-details/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Row,
  },
  props: {
    model: {
      type: Object as PropType<Model>,
      required: true,
    },
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    step: {
      type: String,
      required: true,
    },
  },
  setup() {
    const shelvesStore = useShelves();

    return { shelvesStore };
  },
  computed: {
    shelf() {
      return this.shelvesStore.shelfById(this.suggest.shelf_id);
    },
    needShow(): boolean {
      if (!this.suggest) return false;
      if (this.order.type === 'stowage_market' && this.step === 'shelf') return false;
      if (this.order.type === 'sale_stowage' && this.order.vars.stage === 'trash') {
        return false;
      }
      if (
        this.order.type === OrderTypeEnum.robot_provision &&
        this.suggest?.type === SuggestTypeEnum.box2shelf &&
        !this.suggest.isTargetCanceledOrFailed
      ) {
        return false;
      }
      if (this.isControl && this.suggest.type === 'box2shelf') {
        return true;
      }
      return [
        'check',
        'visual_control',
        'part_refund',
        'stowage',
        'sale_stowage',
        'weight_stowage',
        'stowage_market',
        'refund',
        'shipment_rollback',
        'writeoff',
        'shipment',
        'hand_move',
        'kitchen_provision',
        'robot_provision',
        'repacking',
      ].includes(this.order.type);
    },
    isControl(): boolean {
      return ['writeoff_prepare_day', 'check_valid_regular', 'check_valid_short'].includes(this.order.type);
    },
    label(): string {
      return getShelfTitle({ docType: this.order.type, suggest: this.suggest });
    },
    value(): string {
      if (this.model.shelf) {
        return this.model.shelf.title;
      }
      return this.shelf?.title || '';
    },
  },
  async mounted() {
    if (this.suggest) {
      await this.shelvesStore.getShelfById(this.suggest.shelf_id);
    }
  },
});
</script>
