<template>
  <Layout class="bg-card">
    <Swiper
      :key="suggests.length"
      :space-between="0"
      :modules="[Virtual]"
      virtual
      @swiper="setSwiper"
      @slide-change="({ activeIndex }) => updateActiveSuggestIndex(activeIndex)"
    >
      <SwiperSlide
        v-for="(suggest, index) in suggests"
        :key="suggest.suggest_id"
        :virtual-index="index"
        :data-index="index"
      >
        <ClientOrderSuggestCardWrapper
          :is-visible="activeSuggestIndex === index"
          :order_id="order_id"
          :index="index"
          :suggest="suggest"
          :problem="getProblemByProduct(suggest.product_id)"
          :collected="collected[suggest.suggest_id]"
          :sherlock="sherlockStatuses[suggest.suggest_id]"
          @finish="completeActiveSuggest"
          @input-sherlock-count="onSherlock"
        />
      </SwiperSlide>
    </Swiper>
    <template #footer>
      <LayoutFooter v-if="exp_susanin && suggests.length > 1" class="grid grid-cols-2 gap-x-4 items-center">
        <ProductSwipeButton
          v-if="prevSuggest"
          :suggest="prevSuggest"
          side="left"
          class="px-3 py-1 bg-warmGray-75 rounded-2xl h-14"
          @click="prev"
        />
        <Caption1 v-else class="text-center" color="day-textMinor">
          {{ $gettext('Открыт первый товар из списка') }}
        </Caption1>
        <ProductSwipeButton
          v-if="nextSuggest"
          :suggest="nextSuggest"
          side="right"
          class="px-3 py-1 bg-warmGray-75 rounded-2xl h-14"
          @click="next"
        />
        <Caption1 v-else class="text-center" color="day-textMinor">
          {{ $gettext('Открыт последний товар из списка') }}
        </Caption1>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script lang="ts" setup>
import ClientOrderSuggestCardWrapper from '@/fsd/widgets/order/RequiredCard/ClientOrderSuggestCardWrapper.vue';
import ProductSwipeButton from '@/fsd/widgets/order/RequiredCard/product-swipe-button.vue';
import { SherlockStatus } from '@/fsd/widgets/order/RequiredCard/sherlock/types';
import { useSherlock } from '@/fsd/widgets/order/RequiredCard/sherlock/useSherlock';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import TrueMarkSuggestWrapper from '@/models/TrueMarkSuggestWrapper';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { Collected } from '@/views/Order/types';
import { useCollectOrder } from '@/views/Order/useCollectOrder';
import { Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, reactive, ref, toRef, watch } from 'vue';

interface RequiredCardWrapperProps {
  order_id: OrderOrder['order_id'];
  suggests: (Suggest | TrueMarkSuggestWrapper)[];
  activeSuggestIndex: number;
  activeSuggest: Suggest;
  collected: Record<Suggest['suggest_id'], Collected>;
}

const props = defineProps<RequiredCardWrapperProps>();

const emits = defineEmits<{
  (e: 'finish'): void;
  (e: 'input-sherlock-count', count: number): void;
  (e: 'update:activeSuggestIndex', newVal: number): void;
  (e: 'update:collected', suggest_id: Suggest['suggest_id'], newVal: Collected): void;
}>();

const order = computed(() => {
  return useOrders().orderById(props.order_id) as OrderOrder | undefined;
});
const updateActiveSuggestIndex = (newVal: number) => {
  emits('update:activeSuggestIndex', newVal);
};
const updateCollected = (suggest_id: Suggest['suggest_id'], newVal: Collected) => {
  emits('update:collected', suggest_id, newVal);
};
const { completeActiveSuggest, startBarcodeRequest } = useCollectOrder({
  order_id: toRef(props, 'order_id'),
  order: order,
  suggestsForCollect: toRef(props, 'suggests'),
  activeSuggest: toRef(props, 'activeSuggest'),
  activeSuggestIndex: toRef(props, 'activeSuggestIndex'),
  collected: toRef(props, 'collected'),
  updateActiveSuggestIndex,
  updateCollected,
});

const swiper = ref();
const exp_susanin = computed(() => {
  return useUser().experimentByName('exp_susanin');
});
const prevSuggest = computed(() => {
  if (props.activeSuggestIndex > 0) {
    return props.suggests[props.activeSuggestIndex - 1];
  } else {
    return undefined;
  }
});
const nextSuggest = computed(() => {
  return props.suggests[props.activeSuggestIndex + 1];
});
const sherlockStatuses = reactive<Record<Suggest['suggest_id'], SherlockStatus>>({});

const setSwiper = s => {
  swiper.value = s;
  slideTo(props.activeSuggestIndex);
};
const slideTo = (index: number) => {
  swiper.value?.slideTo(index);
};
const next = () => {
  updateActiveSuggestIndex(props.activeSuggestIndex + 1);
};
const prev = () => {
  updateActiveSuggestIndex(props.activeSuggestIndex - 1);
};

const getProblemByProduct = (product_id: Product['product_id']) => {
  if (!order.value) return undefined;
  if (order.value.target === OrderTargetEnum.canceled) return undefined;
  return order.value?.problems.find(p => p.product_id === product_id);
};
const checkSherlock = async () => {
  if (!props.activeSuggest) return;
  const suggest_id = props.activeSuggest.suggest_id;
  if (sherlockStatuses[suggest_id] === 'skip') return;
  if (sherlockStatuses[suggest_id] === 'need') return;
  const { checkNeedSherlock, sherlockStatus } = useSherlock(props.order_id, props.activeSuggest);
  sherlockStatuses[suggest_id] = sherlockStatus.value;
  if (sherlockStatuses[suggest_id] !== 'maybe') return;
  const needSherlock = await checkNeedSherlock();
  sherlockStatuses[suggest_id] = needSherlock ? 'need' : 'skip';
};
const onSherlock = (count: number) => {
  sherlockStatuses[props.activeSuggest.suggest_id] = 'skip';
  emits('input-sherlock-count', count);
  startBarcodeRequest();
};

watch(
  () => props.activeSuggestIndex,
  idx => {
    slideTo(idx);
    // проверяем нужен ли шерлок когда показываем карточку
    checkSherlock();
  },
  { immediate: true },
);
</script>

<style scoped>
/* НИКОГДА И НЕ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ НЕ ТРОГАТЬ ЭТОТ КЛАСС */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slider img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
